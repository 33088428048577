<template>
    <div class="Merchant">
             <!-- 导航栏组件 -->
         <el-header class="el-headee">
            <BodyNavTop class="el-headerr"></BodyNavTop>
         </el-header>
         <!-- 招聘标题 -->
        <div class="el-headerr">
             <MerTitle></MerTitle>
        <!-- 下半部分的body -->
        <MerBybom></MerBybom>
        </div>
    </div>
</template>

<script>

    //  注册   因为全局注册的时候总是报错，所以干脆全都局部了
import BodyNavTop from '@/components/BodyNavTop.vue'
import MerTitle  from '@/components/MerElement/MerTitle.vue'
import MerBybom from '@/components/MerElement/MerBybom.vue'

import '../../../publicCss/publicCss.css'
export default {
    components:{
        BodyNavTop,
        MerTitle,
        MerBybom

    }
}
</script>

<style scoped lang = 'less'>

</style>