<template>
    <div class="MerByringt">
        <div class="information">
            <h4>公司信息</h4>
            <div class="inform-name" @click="getCompany">
                <div class="left" style="display:flex; align-items:center;">
                      <el-avatar  @click = 'getCompany()' shape="square" :size="50" :src="info.avatar == '' || info.avatar == null ? squareUrl : info.avatar"></el-avatar>
                     <p style="margin-left:4px;"  @click = 'getCompany()'>{{ info.deptName }}</p>
                </div>
                <!-- <button @click = 'button'>{{changebutton}}</button> -->
            </div>
            <div class="inform">
                <p>
                    <span class="title">职位地址：</span>
                    <span>{{ info.jobAddress }}</span>
                </p>
                <p>
                    <span class="title">注册时间：</span>
                    <span>{{ info.createTime }}</span>
                </p>
                 <p>
                    <span class="title">经营期限：</span>
                    <span>{{ info.businessTime }}</span>
                </p>
                <p>
                    <span class="title">经营范围：</span>
                    <span>{{ info.businessScope }}</span>
                </p>
               
            </div>
        </div>
    </div>
</template>

<script>
import { getBusinessInfo } from '@/api/business'
export default {
    data(){
        return{
            changebutton:'关注',
            bu:true,
            squareUrl: "https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png",
            info:{}
        }
    },
    created(){
        this.getInfo()
    },
    methods:{
        button(){
           if(this.bu){
            this.changebutton = '已关注'
            this.bu = false
           }else{
            this.changebutton = '关注'
            this.bu = true
           }
            
        },
        getCompany(){
            getBusinessInfo(this.info.userId).then(res => {
                localStorage.setItem('businessInfo',JSON.stringify(res.data.data))
            })
            this.$router.push('/company')
            
        },
        getInfo(){
            if(localStorage.getItem("prtInfo") == null || localStorage.getItem("prtInfo") == '') {
                this.$router.push("/")
            }
            this.info = JSON.parse(localStorage.getItem("prtInfo"))
        },
    }
}
</script>

<style scoped lang = 'less'>
    .MerByringt{
        width: 80%;
        background: white;
        margin: 0 auto;
        text-align: left;
        padding: 20px;
        box-sizing: border-box;
        h4{
            font-size: 20px;
        }
        .inform-name{
            margin: 20px 0px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
            button{
                border: 0;
                background: #f5f5f5;
                color: orange;
                width: 72px;
                height: 30px;
                font-weight: 700;
                border-radius: 10px;
                cursor: pointer;
            }
        }
        .inform{
            .title{
                font-weight: 700;
                font-size: 16px;
            }
            span{
                font-size: 14px;
            }
            p{
                line-height: 2.2rem;
                
            }
        }
        
    }
</style>