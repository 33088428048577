import request from '@/utils/request'

export function getUserInterViewList(){
    return request({
        url:"/api/jobhunting/getSendInterView",
        method:"post",
        headers:{
            isToken:true
        }
    })
}