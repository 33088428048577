<template>
    <div class="lo">
      <!--  登陆页面  -->
      <div class="login">
        <!-- 登录顶部 -->
        <LoginTop></LoginTop>
        <!-- 中间的输入框组件 -->
        <LoginInput></LoginInput>
      </div>
    </div>
</template>

<script>

  import LoginTop from '../../components/Logion/LoginTop.vue'
  import LoginInput from '@/components/Logion/LoginInput.vue'

  // import '../../../publicCss/publicCss.css'
//获取token
import { computed } from 'vue'
export default {
  components:{
    LoginTop,
    LoginInput
  },
  data(){
    return{
      
    }
  },
}
</script>

<style lang = 'less' scopde>
 
  .login{
    width: 292px;
    padding: 0px 34px 40px 17px;
    background: white;
    border-radius: 10px;
    box-shadow: 0px 2px 5px 0px #d2d2d2;
  }
</style>