import  request from '@/utils/request'

export function getListCarouselsImages(){
    return request({
        url:"/api/carousels/getImages",
        method:"GET",
        headers:{
            isToken:false
        }
    })
}