<template>
  <div class="UserLeft">
    <div class="getword" style="
         padding: 7px 10px;
    background: #ff4600;
    width: 100px;
    border-radius: 10px;
    color: white;
    position: absolute;
    right: 0px;
    top: -21px;
    cursor: pointer;
    "
    @click="getword()"
    >
      导出简历
    </div>
    <div class="content">
      <div class="con-left">
        <!-- 个人  个人  个人  个人 -->
        <div class="user-items item" style="border-radius: 23px 0px 0px 0px;">
          <div class="it-top user-top">
            <div class="userimg">
              <div class="img"  @click="showFileInput">
                 <!-- <img v-if="imageUrl" :src="imageUrl" alt="Profile Picture" style="width: 100px; height: 100px; object-fit: cover; border-radius: 50%;">   -->
                <span>点击上传头像</span>  
                <input type="file" accept="image/*" capture="camera" ref="fileInput" @change="onFileChange" style="display: none;">  
              </div>

              <!-- <el-avatar
               :mode = 'scaleToFill'
                :size="70" 
                :style="{ background: `url(${formLabelAlign.user_avthor || circleUrl})  100%;`}"
                ></el-avatar> -->

                <div class="avatar" style="width: 70px;
    height: 70px; overflow:hidden; border-radius:50%;">
                  <img style="width: 70px;
    height: 70px;" :src="formLabelAlign.user_avthor || circleUrl" alt="">
                </div>

            </div>

            <div class="inform">
              <div class="in-top">
                <span class="name">{{formLabelAlign.user_name}}</span>

                <span @click="User_bianji()" class="bian">编辑</span>
              </div>

              <div class="in-center">
                <span class="age identity">{{formLabelAlign.data_value1}}{{formLabelAlign.radio1}}</span>
                <span class="address">{{formLabelAlign.user_city}}</span>
                <span class="state">{{formLabelAlign.user_value == 0?'在校，看看 机会':formLabelAlign.user_value == 1?'在校，可即刻到岗':formLabelAlign.user_value == 2?'在校，暂时不招工作':formLabelAlign.user_value == 3?'离校，正在找工作':''}}</span>
              </div>

              <div class="in-bottom">
                <span class="phone">{{ PhoneNumber}}</span>
                <span class="user-email">{{formLabelAlign.wx?'wx:'+formLabelAlign.wx:''}}</span>
              </div>
            </div>
          </div>

          <div class="it-bototm user-bototm item-bottom" v-show="formShow">
            <div class="it-bottom-from">
              <el-form :label-position="labelPosition" label-width="80px" :model="formLabelAlign_v">
                <div class="it-form-left">
                  <el-form-item label="名称">
                    <el-input v-model="formLabelAlign_v.user_name"></el-input>
                  </el-form-item>
                  <el-form-item label="当前城市">
                    <el-input v-model="formLabelAlign_v.user_city"></el-input>
                  </el-form-item>

                  <el-form-item label="目前状态">
                    <el-select label="目前状态" v-model="formLabelAlign_v.user_value" placeholder="目前状态">
                      <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                  </el-form-item>

                  <el-form-item label="手机号码">
                    <el-input value v-model="formLabelAlign_v.user_phone" :disabled="false"></el-input>
                  </el-form-item>

                  <!-- <el-form-item label="我的身份">
                    <el-select
                      label="我的身份"
                      v-model="formLabelAlign_v.user_identity"
                      placeholder="我的身份"
                    >
                      <el-option
                        v-for="item in identity"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                  </el-form-item> -->
                </div>
                <div class="it-form-right">
                  <el-form-item label="性别">
                    <el-radio v-model="formLabelAlign_v.radio1" label="0" border>男</el-radio>
                    <el-radio v-model="formLabelAlign_v.radio1" label="1" border>女</el-radio>
                  </el-form-item>

                  <el-form-item label="出生日期">
                    <el-date-picker
                      v-model="formLabelAlign_v.data_value1"
                      type="date"
                      placeholder="选择日期"
                      :picker-options="pickerOptions"
                    ></el-date-picker>
                  </el-form-item>

                  <el-form-item label="政治面貌">
                    <el-select
                      label="政治面貌"
                      v-model="formLabelAlign_v.user_politics"
                      placeholder="政治面貌"
                    >
                      <el-option
                        v-for="item in politics"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                  </el-form-item>

                  <el-form-item label="微信号">
                    <el-input v-model="formLabelAlign_v.wx"></el-input>
                  </el-form-item>
                </div>
              </el-form>
            </div>

            <div  class="btn">
              <div class="close" @click="User_close()">取消</div>
              <div @click="get_formShow1" class="confirm">确认</div>
            </div>
          </div>
        </div>

        <!-- 

                    优势亮点  优势亮点   优势亮点

        -->
        <div class="Advantage-items item" style="border-radius: 0px 0px 22px 0px;">
          <div class="items-top">
            <div class="title">
              <h3>优势亮点</h3>
            </div>
            <div class="jia">
              <i @click="openTwo()" class="el-icon-circle-plus"></i>
            </div>
          </div>
         
          <div class="show" v-show="!formShow2">
            <div>
              <!-- <div class="show">
                 <p v-show="!formShow2">{{Advantage_form_list?'':'编辑优势亮点，展示我独特态度'}}</p>
             </div> -->
             <div class="show-content">
                <p>{{Advantage_form_list.length == 0?'编辑优势亮点，展示我独特态度':''}}</p>
              </div>
              <ul>
                <!-- 

                    要渲染的li

                -->
                <li class="expect-li items-lii itit" v-for="(item,index) in Advantage_form_list" :key="index">
                  <div class="show-content">
                    <p>{{item}}</p>
                  </div>
                  <div class="bianji">
                    <span class="del" @click="delList(Advantage_form_list,index,'优势亮点')">删除</span>
                    <span class="gang">/</span>
                    <span class="bian" @click="Advantage_bianji(index)">编辑</span>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div class="content-bottom item-bottom" v-show="formShow2">
            <div class="it-bottom-from">
              <el-form :label-position="labelPosition" label-width="80px" :model="Advantage_form">
                <el-form-item label="优势亮点">
                  <el-input
                    type="textarea"
                    placeholder="请输入内容"
                    v-model="advan_textarea_v"
                    maxlength="2000"
                    show-word-limit
                    rows="10"
                  ></el-input>
                </el-form-item>
              </el-form>
            </div>
            
            <div class="btn">
              <div @click="formShow2=false" class="close">取消</div>
              <div @click="get_formShow2" class="confirm">确认</div>
            </div>
          </div>
        </div>

        <!-- 

                    求职期望    求职期望    求职期望    求职期望

        -->
        <div class="expect-items item">
          <div class="items-top">
            <div class="title">
              <h3>求职期望</h3>
            </div>
            <div class="jia">
              <i @click="formShow3=true" class="el-icon-circle-plus"></i>
            </div>
          </div>

          <div class="show" v-show="!formShow3">
            <div>
              <ul>
                <!-- 

                    要渲染的li

                -->
                <li class="expect-li items-lii itit" v-for="(item,index) in expect_list" :key="index">
                  <div class="show-content">
                    <span class="Position">{{item.expectJob}}</span>
                    <span class="money">{{item.expectIndutry}}</span>
                    <span class="address">{{item.expectJobAddress}}</span>
                    <span class="industry">{{item.expectIndutry}}</span>
                    <span class="industry">{{item.expectPay}}</span>
                    <span class="identity">{{item.expectMark ==1?'兼职':'全职'}}</span>
                  </div>
                  <div class="bianji">
                    <span class="del" @click="delList(expect_list,index,'期望')">删除</span>
                    <span class="gang">/</span>
                    <span class="bian" @click="bianjiList('expect_form',expect_list,'expect_change','expect_ChangeIndex',index,3)">编辑</span>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div class="content-bottom item-bottom" v-show="formShow3">
            <div class="it-bottom-from">
              <el-form :label-position="labelPosition" label-width="80px" :model="expect_form">
                <div class="it-form-left">
                  <el-form-item label="期望类型">
                    <el-radio v-model="expect_form.expectMark" label="0" border>兼职</el-radio>
                    <el-radio v-model="expect_form.expectMark" label="1" border>全职</el-radio>
                  </el-form-item>

                  <el-form-item label="期望地点">
                    <el-input v-model="expect_form.expectJobAddress"></el-input>
                  </el-form-item>

                  <el-form-item label="期望行业">
                    <el-input v-model="expect_form.expectIndutry"></el-input>
                  </el-form-item>
                </div>
                <div class="it-form-right">
                  <el-form-item label="期望职位">
                    <el-input v-model="expect_form.expectJob"></el-input>
                  </el-form-item>

                  <el-form-item label="期望薪资·月薪(K)" >
                      <div style="display:flex;">
                        <el-input v-model="expectMoneyOne"></el-input>
                      <span style="margin:0px 4px;">-</span>
                       <el-input v-model="expectMoneyTwo"></el-input>
                      </div>
                  </el-form-item>
                </div>
              </el-form>
            </div>

            <div class="btn">
              <div @click="closeBtn('formShow3','expect_form','expect_change')" class="close">取消</div>
              <div @click="get_formShow3" class="confirm">确认</div>
            </div>
          </div>
        </div>

        <!-- 

                        工作经历 工作经历  工作经历  工作经历 

        -->
        <div class="exper-items item">
          <div class="items-top">
            <div class="title">
              <h3>工作经历</h3>
            </div>
            <div class="jia">
              <i @click="formShow4=true" class="el-icon-circle-plus"></i>
            </div>
          </div>

          <div class="show" v-show="!formShow4">
            <div class="show-content">
              <div>
                <ul>
                  <!-- 
                        要渲染的li

                  -->
                  <li class="exper-li items-lii itit" v-for="(item,index) in work_list" :key="index">
                    <div class="work">
                      <div class="work-top">
                        <div class="work-name">{{item.practiceLtd}}</div>

                        <div class="work-time">
                          <span class="lishi">{{item.practiceStartTime?item.practiceStartTime+'---'+item.practiceMoveTime:''}}</span>
                          <!-- <span>-</span> -->
                          <!-- <span class="now">至今</span> -->
                        </div>
                      </div>

                      <div class="work-content">
                        <p>{{item.practicePosiion}}&nbsp;&nbsp;&nbsp;{{item.positionName}}</p>
                        <!-- <span>行业</span> -->
                        <!-- <span>{{item.positionType}}</span> -->
                      </div>

                      <div class="perform">
                        <p class="performance">{{item.positionText}}</p>
                      </div>
                    </div>
                    <div class="bianji">
                      <span class="del" @click="delList(work_list,index,'工作经历')">删除</span>
                      <span class="gang">/</span>
                      <span class="bian" @click="bianjiList('work_form',work_list,'work_change','work_ChangeIndex',index,4)">编辑</span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="content-bottom item-bottom" v-show="formShow4">
            <el-form :label-position="labelPosition" label-width="80px" :model="work_form">
              <div class="form-top">
                <div class="it-form-left">
                  <el-form-item label="公司名称">
                    <el-input v-model="work_form.practiceLtd"></el-input>
                  </el-form-item>
                  <el-form-item label="在职时间">
                    <el-date-picker
                      v-model="work_date"
                      type="daterange"
                      range-separator="至"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                       value-format="yyyy-MM-dd"
                       :picker-options="pickerNow"
                    ></el-date-picker>
                  </el-form-item>

                  <el-form-item label="职位名称">
                    <el-input v-model="work_form.positionName"></el-input>
                  </el-form-item>
                </div>
                <div class="it-form-right">
                  <!-- <el-form-item label="所属行业">
                    <el-input v-model="work_form.pre_name"></el-input>
                  </el-form-item> -->
                  <el-form-item label="职务">
                    <el-input v-model="work_form.practicePosiion"></el-input>
                  </el-form-item>
                </div>
              </div>
              <div class="form-bottom">
                <el-form-item label="职责业绩">
                  <el-input
                    type="textarea"
                    placeholder="请输入内容"
                    v-model="work_form.positionText"
                    maxlength="2000"
                    show-word-limit
                    rows="10"
                  ></el-input>
                </el-form-item>
              </div>
            </el-form>
            <div class="btn">
              <div @click="closeBtn('formShow4','work_form','work_change')" class="close">取消</div>
              <div @click="get_formShow4()" class="confirm">确认</div>
            </div>
          </div>
        </div>

         <!-- 

                        项目经历    项目经历    项目经历    项目经历    项目经历

        -->
        <div class="project-items item">
          <div class="items-top">
            <div class="title">
              <h3>项目经历</h3>
            </div>
            <div class="jia">
              <i @click="formShow5=true" class="el-icon-circle-plus"></i>
            </div>
          </div>

          <div class="show" v-show="!formShow5">
            <div class="show-content">
              <div class="project-content">
                <ul class="project-ul">
                  <!-- 
                        要渲染的li

                  -->
                  <li class="project-items items-lii itit" v-for="(item,index) in project_list" :key="index">
                    <div class="bot-top">
                      <div class="pro-item-top">
                        <div class="work-name">{{item.projectName}}</div>

                        <div class="work-time">
                          <span class="lishi">{{item.projectDate}}</span>
                        </div>
                      </div>

                      <div class="pro-item-center">
                        <div class="pro-address">
                          <span class="pro-comppany">{{item.projectCompany}}</span>
                          <span>·</span>
                          <span class="positi">{{item.projectPosition}}</span>
                        </div>
                      </div>

                      <div class="discribe">
                        <p class="pro-dis">{{item.projectDescribe}}</p>
                      </div>
                    </div>
                    <div class="bianji">
                      <span class="del" @click="delList(project_list,index,'项目经历')">删除</span>
                      <span class="gang">/</span>
                      <span class="bian" @click="bianjiList('project_form',project_list,'project_change','project_ChangeIndex',index,5)">编辑</span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="content-bottom item-bottom" v-show="formShow5">
            <el-form :label-position="labelPosition" label-width="80px" :model="work_form">
              <div class="form-top">
                <div class="it-form-left">
                  <el-form-item label="项目名称">
                    <el-input v-model="project_form.projectName"></el-input>
                  </el-form-item>
                  <el-form-item label="公司名称">
                    <el-input v-model="project_form.projectCompany"></el-input>
                  </el-form-item>
                </div>
                <div class="it-form-right">
                  <el-form-item label="项目日期">
                    <el-date-picker
                      v-model="project_form.projectDate"
                      type="daterange"
                      range-separator="至"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                       value-format="yyyy-MM-dd"
                       :picker-options="pickerNow"
                    ></el-date-picker>
                  </el-form-item>
                  <el-form-item label="项目职务">
                    <el-input v-model="project_form.projectPosition"></el-input>
                  </el-form-item>
                </div>
              </div>
              <div class="form-bototm">
                <el-form-item label="项目描述">
                  <el-input
                    type="textarea"
                    placeholder="请输入内容"
                    v-model="project_form.projectDescribe"
                    maxlength="2000"
                    show-word-limit
                    rows="10"
                  ></el-input>
                </el-form-item>
                <el-form-item label="项目职责">
                  <el-input
                    type="textarea"
                    placeholder="请输入内容"
                    v-model="project_form.projectZe"
                    maxlength="2000"
                    show-word-limit
                    rows="10"
                  ></el-input>
                </el-form-item>
                <el-form-item label="职责业绩">
                  <el-input
                    type="textarea"
                    placeholder="请输入内容"
                    v-model="project_form.projectPerform"
                    maxlength="2000"
                    show-word-limit
                    rows="10"
                  ></el-input>
                </el-form-item>
              </div>
            </el-form>
            <div class="btn">
              <div @click="closeBtn('formShow5','project_form','object_change')" class="close">取消</div>
              <div @click="get_formShow5()" class="confirm">确认</div>
            </div>
          </div>
        </div>
      </div>

      <div class="con-right">
       

        <!-- 

                    教育经历  教育经历  教育经历  教育经历  教育经历 教育经历

        -->
        <div class="edu-items item">
          <div class="items-top">
            <div class="title">
              <h3>教育经历</h3>
            </div>
            <div class="jia">
              <i @click="formShow6=true" class="el-icon-circle-plus"></i>
            </div>
          </div>

          <div class="show" v-show="!formShow6">
            <div class="show-content">
              <div>
                <ul>
                  <!-- 
                        要渲染的li

                  -->
                  <li class="edu-li items-lii itit" v-for="(item,index) in edu_list" :key="index">
                    <div class="edu">
                      <div class="edu_logo" style=" background: #efefef;
                                              border-radius: 10px;
                                              padding: 1px 3px;">
                        <!-- <el-avatar :size="50" :src="edu_circleUrl"></el-avatar> -->
                        <img style="width: 52px;" src="../../../assets/school.png" alt="">
                      </div>
                      <div  class="edu_content">
                        <div style="margin-bottom:15px;" class="edu_content-top">
                          <span class="school_name">{{item.schoolName}}</span>
                          <span class="edu_Time">{{item.schoolEduTime}}</span>
                          <!-- <span class="gang">-</span> -->
                          <!-- <span></span> -->
                        </div>
                        <div></div>
                        <div class="school_type">
                          <span class="type">{{item.schoolXueValue}}</span>
                          <span class="xian">|</span>
                          <span class="school_zhuan">{{item.schoolMajorName}}</span>
                        </div>
                      </div>
                    </div>
                    <div class="bianji">
                      <span class="del" @click="delList(edu_list,index,'教育经历')">删除</span>
                      <span class="gang">/</span>
                      <span class="bian" @click="bianjiList('edu_form',edu_list,'study_change','study_ChangeIndex',index,6)">编辑</span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="content-bottom item-bottom" v-show="formShow6">
            <el-form :label-position="labelPosition" label-width="80px" :model="edu_form">
              <div class="form-top">
                <div class="it-form-left">
                  <el-form-item label="学校名称">
                    <el-input v-model="edu_form.schoolName"></el-input>
                  </el-form-item>

                  <el-form-item label="专业名称">
                    <el-input v-model="edu_form.schoolMajorName"></el-input>
                  </el-form-item>
                </div>

                <div class="it-form-right">
                  <el-form-item label="学历">
                    <el-select label="学历" v-model="edu_form.schoolXueValue" placeholder="学历">
                      <el-option
                        v-for="item in edu_xueList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                  </el-form-item>

                  <el-form-item label="日期">
                    <el-date-picker
                      v-model="edu_form.schoolEduTime"
                      type="daterange"
                      range-separator="至"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                      value-format="yyyy-MM-dd"
                      :picker-options="pickerNow"
                    ></el-date-picker>
                  </el-form-item>
                </div>
              </div>
              <div class="form-bottom">
                <el-form-item label="在校经历">
                  <el-input
                    type="textarea"
                    placeholder="请输入内容"
                    v-model="edu_form.schoolIn"
                    maxlength="2000"
                    
                    show-word-limit
                    rows="10"
                  ></el-input>
                </el-form-item>
              </div>
            </el-form>
            <div class="btn">
              <div @click="closeBtn('formShow6','edu_form','study_change')"  class="close">取消</div>
              <div @click="get_formShow6()" class="confirm">确认</div>
            </div>
          </div>
        </div>

        <!-- 

                资格证书    资格证书    资格证书    资格证书    资格证书


        -->
        <div class="certificate-items item">
          <div class="items-top">
            <div class="title">
              <h3>资格证书</h3>
            </div>
            <div class="jia">
              <i @click="formShow7=true" class="el-icon-circle-plus"></i>
            </div>
          </div>
          <div class="show" v-show="!formShow7">
            <div class="show-content  show_span">
              <div>
                  <span v-for="(item,index) in Cer_list" :key="index">
                    {{item}}
                    <i  @click="clear_span(Cer_list,index,'荣誉证书')" class="el-icon-close"></i>
                  </span>
              </div>
            </div>
          </div>
          <div class="cer-content item-bottom"  v-show="formShow7">
            <el-form :label-position="labelPosition" label-width="80px" :model="Certificate_form">
              <el-form-item label="请填写您所拥有过的资质证书，一次填写一个就好">
                    <el-input v-model="Certificate_form.Cer_input"></el-input>
              </el-form-item>
            </el-form>
            <div class="btn">
              <div @click="formShow7=false" class="close">取消</div>
              <div @click="get_formShow7()" class="confirm">确认</div>
            </div>
          </div>
        </div>

        <!-- 

                技能标签   技能标签 技能标签    技能标签    技能标签

        -->
        <div class="skill-items item">
          <div class="items-top">
            <div class="title">
              <h3>技能标签</h3>
            </div>
            <div class="jia">
              <i @click="formShow8=true" class="el-icon-circle-plus"></i>
            </div>
          </div>
          <div class="show" v-show="!formShow8">
            <div class="show-content  show_span">
              <div>
                <span v-for="(item,index) in skill_list" :key="index">{{item}}
                  <i  @click="clear_span(skill_list,index,'技能标签')" class="el-icon-close"></i>
                </span>
              </div>
            </div>
          </div>
          <div class="skill-content item-bottom"  v-show="formShow8">
            <el-form :label-position="labelPosition" label-width="80px" :model="skill_form">
              <el-form-item label="请输入您所擅长的技能，最多添加10个">
                    <el-input v-model="skill_form.skill_input"></el-input>
              </el-form-item>
            </el-form>
            <div class="btn">
              <div @click="formShow8=false" class="close">取消</div>
              <div @click="get_formShow8()" class="confirm">确认</div>
            </div>
          </div>
        </div>

        <!-- 

                语言能力 语言能力   语言能力    语言能力    语言能力

        -->
        <!-- <div class="lang-items item">
          <div class="items-top">
            <div class="title">
              <h3>语言能力</h3>
            </div>
            <div class="jia">
              <i @click="formShow9=true" class="el-icon-circle-plus"></i>
            </div>
          </div>
          <div class="show" v-show="!formShow9">
            <div class="show-content   show_span">
              <div>
                <span v-for="(item,index) in lang_form.lang_list" :key="index">{{item}}
                  <i  @click="clear_span(lang_form.lang_list,index,'语言能力')" class="el-icon-close"></i>
                </span>
              </div>
            </div>
          </div>
          <div class="content-bottomt item-bottom"  v-show="formShow9">
            <el-form :label-position="labelPosition" label-width="80px" :model="lang_form">
              <el-form-item label="请输入您所擅长的语言，限制五个">
                    <el-input v-model="lang_form.lang_input"></el-input>
              </el-form-item>
            </el-form>
            <div class="btn">
              <div @click="formShow9=false" class="close">取消</div>
              <div @click="get_formShow9()" class="confirm">确认</div>
            </div>
          </div>
        </div> -->

        <!--

               附加信息  附加信息  附加信息  附加信息 附加信息

        -->

        <div class="additional-items item">
          <div class="items-top">
            <div class="title">
              <h3>附加信息</h3>
            </div>
            <div class="jia">
              <i @click="formShow10=true" class="el-icon-circle-plus"></i>
            </div>
          </div>

          <div class="show">
            <p v-show="!formShow10">{{additional_form.additional_textarea}}</p>
          </div>

          <div class="content-bottom item-bottom" v-show="formShow10">
            <div class="it-bottom-from">
              <el-form :label-position="labelPosition" label-width="80px" :model="additional_form">
                <el-form-item label="附加信息">
                  <el-input
                    type="textarea"
                    placeholder="请输入内容"
                    v-model="additional_form.additional_textarea"
                    maxlength="2000"
                    show-word-limit
                    rows="10"
                  ></el-input>
                </el-form-item>
              </el-form>
            </div>
            <div class="btn">
              <div @click="formShow10=false" class="close">取消</div>
              <div @click="formShow10=get_formShow10()" class="confirm">确认</div>
            </div>
          </div>
        </div>

        <!-- 

            个人作品    个人作品    个人作品    个人作品

        -->
        <div class="personal_works-items item">
          <div class="items-top">
            <div class="title">
              <h3>个人作品</h3>
            </div>
            <div class="jia">
              <i @click="formShow11=true" class="el-icon-circle-plus"></i>
            </div>
          </div>
          <div class="show" v-show="!formShow11">
            <div>
              <ul>
                <!-- 

                    要渲染的li

                -->
                <li v-for="(item,index) in personwork_form_list" :key="index" class="expect-li items-lii itit">
                  <div class="show-content">
                    <div class="top">
                      <h4 >{{item.opusName}}</h4>
                      <a class="href_a" target="_blank" :href="item.opusUrl"><span>路径：</span>{{item.opusUrl}}</a>
                    </div>
                    <div class="textarea">
                      <p>{{item.opusText}}</p>
                    </div>
                  </div>
                  <div class="bianji">
                    <span @click="delList(personwork_form_list,index,'个人作品')" class="del">删除</span>
                    <span class="gang">/</span>
                    <span @click="bianjiList('personwork_form',personwork_form_list,'isChange','ChangeIndex',index,11)" class="bian">编辑</span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="content-bottom item-bottom" v-show="formShow11">
            <div class="it-bottom-from">
              <el-form :label-position="labelPosition" label-width="80px" :model="personwork_form">
                <div class="work1">
                  <el-form-item label="作品名称">
                      <el-input v-model="personwork_form.opusName"></el-input>
                  </el-form-item>
                </div>
                <div class="work2">
                  <el-form-item label="链接作品">
                    
                    <p>直接添加作品链接更能体现个人实力哦</p>
                    <div class="link">
                      <input
                        type="text"
                        placeholder="例:https://www.xiaoyuanzhaopin.com"
                        maxlength="200"
                        class="ant-input"
                        value
                        v-model="personwork_form.opusUrl"
                      />
                    </div>
                  </el-form-item>
                </div>
                <div class="work3">
                   <el-form-item label="作品描述">
                      <el-input
                    type="textarea"
                    placeholder="请输入内容"
                    v-model="personwork_form.opusText"
                    maxlength="2000"
                    show-word-limit
                    rows="10"
                  ></el-input>
                  </el-form-item>
                </div>
              </el-form>
            </div>
            <div class="btn">
              <div @click="closeBtn('formShow11','personwork_form','isChange')" class="close">取消</div>
              <div @click="get_formShow11" class="confirm">确认</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import "@/assets/userCss.css";


import { getUserInfoApi, // 获取用户信息
        updateUsetInfoApi,  // 修改用户信息
        getUserAdvantageApi, // 获取用户优势亮点
        updateUserAdvantageApi, // 修改用户优势亮点
         getUserhonor ,// 获取用户的荣誉证书
        updataUserhonor, // 添加用户的荣誉证书
        getUserappendix,// 获取用户的附件信息
        updataUserappendix,//添加用户的附件信息
        } from "@/api/userJobhuning"

import {
        getUserexpectApi,      // 获取用户求职期望
        updataUserexpectApi,  // 添加用户求职期望
        editUserexpectApi, // 编辑用户求职期望
        popUserexpectApi    // 删除用户求职期望
} from "@/api/userExpect"

import {
    getUserWork, // 工作经历获取
    appendUserWork,//工作经历添加
    edittUserWork,//工作经历修改
    popUserWork,//工作经历删除
} from "@/api/userWorkLi"

import {
    getUserProject, // 项目经历获取
    appendUserProject,//项目经历添加
    editUseProject,//项目经历修改
    updateUserProject,//项目经历删除
} from "@/api/objectFooo"

import {
    getUserStudy, // 教育经历获取
    appendUserStudy,//教育经历添加
    editUserStudy,//教育经历修改
    updateUserStudy,//教育经历删除
} from "@/api/studyInfo"

import {getUserSkill,updataUserSkill} from "@/api/resumesFo" // 技能标签获取

import {
    getUserOpusListByUserId, // 教育经历获取
    updataUserOpusListByUserId,//教育经历添加
    editUserOpusListByUserId,//教育经历修改
    popUserOpusListByUserId,//教育经历删除
} from "@/api/userOpus"
import axios from 'axios';
import {getToken} from "@/utils/auth"

export default {
  data() {
    return {
      // 当前日期
      nowDay:'',
      // 只能选择18年以前的日期
     pickerOptions: {  
      disabledDate(time) {  
        // 计算18年前的日期  
        const eighteenYearsAgo = new Date();  
        eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18);  
      
        // 禁用今天及以后的日期，只允许选择18年以前的日期  
        return time.getTime() > eighteenYearsAgo.getTime();  
      }  
    },

    // 只能选择到至今的日期
    pickerNow: {  
        disabledDate(time) {  
          // 设置禁用日期为今天之后的日期  
          return time.getTime() > new Date().getTime();  
        }  
    } ,

      // 表单的默认隐藏
      formShow: false,
      formShow2: false,
      formShow3: false,
      formShow4: false,
      formShow5: false,
      formShow6: false,
      formShow7: false,
      formShow8: false,
      formShow9: false,
      formShow10: false,
      formShow11: false,

      // 表单label位置
      labelPosition: "top",

      // 用户头像
      circleUrl:"https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",

      formLabelAlign: {
        user_name:'',
        // 个人状态的value
        user_value: "",
        // 个人状态的value  身份
        // user_identity: "身份",
        // 政治面貌的value
        user_politics: "",
        //   个人的wx
        wx: "",
        // 出生日期
        data_value1: "",
        // 性别  女1  男0
        radio1: "1",
        // 所在城市
        user_city: "",
        user_phone:'',
        user_avthor:'',
      },
       formLabelAlign_v: {
        user_name:'',
        // 个人状态的value
        user_value: "",
        // 个人状态的value  身份
        user_identity: "",
        // 政治面貌的value
        user_politics: "",
        //   个人的wx
        wx: "",
        // 出生日期
        data_value1: "",
        // 性别  男1  女2
        radio1: "",
        // 所在城市
        user_city: "",
        // 手机号
        user_phone:'',
      },
      formUser:{

      },
      UserId:'',
      studentId:'',
      //   个人状态
        options: [
          { value: "0", label: "在校，看看机会" },
          { value: "2", label: "在校，暂时不招工作" },
          { value: "1", label: "在校，可即刻到岗" },
          { value: "3", label: "离校，正在找工作" },
        ],
        //  个人身份
        identity: [
          { value: "在校生", label: "在校生" },
          { value: "应届毕业生", label: "应届毕业生" },
          { value: "实习生", label: "实习生" }
        ],

        // 政治面貌
        politics: [
          { value: "中共党员", label: "中共党员" },
          { value: "中共预备党员", label: "中共预备党员" },
          { value: "共青团员", label: "共青团员" },
          { value: "群众", label: "群众" },
          { value: "其它", label: "其它" }
        ],


      //   优势亮点的表单  优势亮点的表单  优势亮点的表单  优势亮点的表单
      Advantage_form: {
        advan_textarea: ""
      },
      // 优势亮点v-model
      advan_textarea_v:'',
    // 优势亮点渲染表单
    Advantage_form_list:[

    ],



      //   求值期望的表单  求值期望的表单 求值期望的表单 求值期望的表单
        // 要渲染的求值期望表单
        expect_list: [
   
        ],
        expect_form: {
          // // 求值期望的身份
          // radio1: "1", // 1是应届 2是实习
          // // 期望求值的地点
          // address: "",
          // // 期望求值的行业
          // inform: "",
          // // 期望求值的职业
          // profession: "",
         
          // // 薪资的的value
          // user_politics: "",
          // // 最低薪资
          // user_politicsOne:'',
          // // 最高薪资
          // user_politicsTwo:''
        },
        expectId:'',
        expectMoneyOne:'',
        expectMoneyTwo:'',


      //   工作经历的表单 工作经历的表单   工作经历的表达那
      // 工作经历的渲染表单
      work_list:[
       
      ],
      work_form: {
        name: "",
        //   工作经历的日期
        practiceStartTime: "",
        practiceMoveTime:'',
        // 工作经历的
        positionText: "",
        // 公司名称
        practiceLtd: "",
        // 职位名称
        positionName: "",
        // 所属行业
        // pre_name: "",
        // 项目职务
        practicePosition:'',
        // 职位类型
        positionType:'',
      },
      work_date:'',


      //    项目经历的表单  项目经历的表单 项目经历的表单
     //  需要遍历的数组
        project_list: [
            
        ],
      project_form: {
        // 项目名称
        projectName: "",
        // 项目日期
        projectDate: "",
        // 公司名称
        projectCompany: "",
        // 项目职务
        projectPosition: "",
        // 项目描述的文本域
        projectDescribe: "",
        // 项目职责的文本域
        projectZe: "",
        // 项目业绩的文本域
        projectPerform: ""
      },



      //     教育经历 教育经历    教育经历    教育经历    教育经历
      // 要渲染的list
      edu_list: [
  
      ],



       // 学校logo
        edu_circleUrl:"https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
      edu_form: {
        // 学校名称
        schoolName: "",
        // 专业名称
        schoolMajorName: "",
        // 时间
        schoolEduTime: "",
        // 在校经历
        schoolIn: "",
        // 学历值
        schoolXueValue: "",
      },


      edu_xueList: [
          { label: "博士", value: "博士" },
          { label: "MBA/RMBA", value: "MBA/RMBA" },
          { label: "硕士", value: "硕士" },
          { label: "本科", value: "本科" },
          { label: "大专", value: "大专" },
          { label: "中专/中技", value: "中专/中技" },
          { label: "初中及以下", value: "初中及以下" }
      ],


      // 资质证书的表单   资质证书  资质证书  资质证书  资质证书
      Certificate_form:{
          // input的v-model
          Cer_input:'',
      },
       // 要渲染的列表
        Cer_list:[],

      // 技能标签的表单   技能标签  技能标签  技能标签  技能标签
      skill_form:{
        // input的v-model
          skill_input:'',
      },
      // 要渲染的列表
      skill_list:[],

      //       语言能力   语言能力    语言能力    语言能力
      lang_form: {
        // 要渲染的list
        lang_list: [],
       
        // 语言input的v-model
        lang_input: "",
       
      },
      //     附加信息的表单  附加信息的表单  附加信息的表单  附加信息的表单
      additional_form: {
        // 附加信息的文本域
        additional_textarea: ""
      },
      //   个人作品
      personwork_form: {
          // 作品名称
            opusName:'',
            // 作品链接
            opusUrl:'',
            // 作品描述
            opusText:'',
      },
      // 个人作品的表单  个人作品的表单  个人作品的表单
      personwork_form_list:[
        
      ],
       
      
     //  个人作品是否修改模式
           isChange:false,
            // 修改下标
        ChangeIndex:0, 
        // 优势亮点是否修改模式
        Advantage_change:false,
        // 修改下标
        Advantage_ChangeIndex:0,

        // 教育经历是否修改模式
          study_change:false,
           // 修改下标
          study_ChangeIndex:0, 
          // 项目经历是否修改模式
          project_change:false,
           // 修改下标
          project_ChangeIndex:0, 
          // 工作经历是否修改模式
          work_change:false,
          // 修改下标
          work_ChangeIndex:0, 
          // 求职期望是否修改模式
          expect_change:false,
          // 修改下标
          expect_ChangeIndex:0, 
       
    };
  },
  created(){
    // 用户用户基本信息
    this.getUserInfoTop()
    // 获取用户优势亮点
    this.getUserInfoAdv()
    // 获取用户的求职期望
    this.getUserInfoexpect()
    // 获取用户的工作经历
    this.getUserInfoWork()
    // 获取用户的教育经历
    this.getUserInfoStudy()
    // 获取用户的荣誉证书
    this.getUserinfoHoner()
    // 获取用户的技能标签
    this.getUserinfoSkill()
    // 加载用户的附加信息
    this.getUserInfoappendix()
    // 加载用户的个人作品信息
    this.getUserInfoopus()
  },
  methods: {
    // 跳转到word
    getword(){
      this.$router.push('/word')
      console.log(111);
    },


    // 加载用户信息
    getUserInfoTop(){
        getUserInfoApi().then(res => {
          const data = res.data.data
          this.formLabelAlign.user_name = data.nickName // 名称
          this.formLabelAlign.user_avthor = data.avatar // 头像
          this.formLabelAlign.user_phone = data.phonenumber // 手机号
          this.formLabelAlign.radio1 = data.sex // 性别
          this.formLabelAlign.data_value1 = data.dateBirth // 日期
          this.formLabelAlign.user_city = data.city // 城市
          this.formLabelAlign.user_value =data.status // 状态
          this.formLabelAlign.wx = data.userWechat // 微信
          this.UserId = data.userId
          this.studentId = data.studentId
        })
    },
    // 加载用户优势亮点
    getUserInfoAdv(){
        getUserAdvantageApi().then(res =>{
          const data = res.data
          console.log(data,'data');
          if(data.msg == ''){
            console.log(this.Advantage_form_list,'aaa');
            return
          }
          console.log(data.msg,'msg');
          this.Advantage_form.advan_textarea = data.msg
          // console.log(data,'优势亮点');
          // 处理之后返回给数组
          this.Advantage_form_list = this.Advantage_form.advan_textarea.split('-')
          console.log(this.Advantage_form_list,'data-list');
        })
    },

    // 加载用户的求职期望
    getUserInfoexpect(){
      getUserexpectApi().then(res =>{
        const data = res.data
        // 赋值给列表
        this.expect_list = data.data
      })
    },
      // 加载工作经历信息
      getUserInfoWork(){
        getUserWork().then(res =>{
          const data = res.data
          // 赋值给列表
          this.work_list = data.data
        })
       
      },
    // 加载项目经历信息
    getUserInfoObject(){
        getUserProject().then(res =>{
          const data = res.data
          // 赋值给列表
          this.project_list = data.data
        })
    },
    // 加载教育经历信息
    getUserInfoStudy(){
      getUserStudy().then(res =>{
        const data = res.data
        // 赋值给列表
        this.edu_list = data.data
        // console.log(data.data,'教育经历列表');
      })
    },

    // 加载用户的荣誉证书信息
    getUserinfoHoner(){
      getUserhonor().then(res =>{
        const data = res.data
        this.Cer_list = data.data
      })
    },

    // 加载用户的技能标签信息
    getUserinfoSkill(){
      getUserSkill().then(res =>{
        const data = res.data
        this.skill_list = data.data
        console.log(this.skill_list,'获取');
      })
    },
    // 加载用户的附加信息
    getUserInfoappendix(){
      getUserappendix().then(res =>{
        const data = res.data
        this.additional_form.additional_textarea = data.data
      })
    },

    // 加载用户的个人作品信息
    getUserInfoopus(){
      getUserOpusListByUserId().then(res =>{
        const data = res.data
        this.personwork_form_list = data.data
      })
    },

    // 头像上传
    showFileInput() {  
      this.$refs.fileInput.click(); // 触发文件输入元素的点击事件  
    }, 
     onFileChange(e) {  
      const file = e.target.files[0];  
      if (file) {  
        // 在这里你可以进行文件的处理，例如：上传、预览等  
  
        // 以下是一个简单的预览功能  
        const reader = new FileReader();  
        reader.onload = (e) => {  
          this.formLabelAlign.user_avthor = e.target.result; // 将图片的Base64编码赋值给imageUrl  
        };  
        reader.readAsDataURL(file);  
  
        // 如果需要上传文件到服务器，你可以在这里添加代码，例如使用axios或fetch等库发送请求  
        console.log(file,"file")
        let formData = new FormData();
        formData.append("file",file);
        console.log(formData,"formData")
        axios.post(this.$BASE_URL+'/common/uploadUser',formData,{
          headers:{
            "Content-Type":"multipart/form-data",
            "Authorization": 'Bearer '+ getToken()
          }
        }).then(res => {
          // console.log(res)
          this.formLabelAlign.user_avthor =res.data.fileName
          this.User_bianji()
          this.get_formShow1()
        })
      }  
    },  

    // 个人信息点击编辑事件
    User_bianji(){
      this.formLabelAlign_v = {...this.formLabelAlign}
      this.formShow = true
    },
    // 个人信息点击取消事件
    User_close(){

      this.formLabelAlign_v = {}
      this.formShow = false
    },

    // 个人信息表确认事件
    get_formShow1() {
      // 判断名称   长度为1-8字符只能包含数字、下划线
     const nameRegex = /^[a-zA-Z0-9_\u4e00-\u9fa5]{1,8}$/;
      if (!nameRegex.test(this.formLabelAlign_v.user_name) || this.formLabelAlign_v.user_name.length == 0) {
            alert('名称长度为1-8字符，只能包含文字，数字，字母，下划线')
            return 
      } 
      // 判断当前城市   只能是文字
      const cityRegex =  /^[\u4e00-\u9fa5]+$/
      if(!cityRegex.test(this.formLabelAlign_v.user_city)){
        alert('请输入正确的城市名称')
        return
      }
       const phoneRegex = /^1[3-9]\d{9}$/;
        if (!phoneRegex.test(this.formLabelAlign_v.user_phone)) {
             alert('请输入正确的手机号格式')
             return
        } 
        console.log(this.formLabelAlign_v.user_phone);
      // 目前只判断上面这俩

      // console.log(this.formLabelAlign_v.data_value1)
      this.nowDay = new Date().getTime()
      console.log(this.nowDay);
      // 下面直接赋值确认
      this.formLabelAlign = {...this.formLabelAlign_v}

        this.formUser = {
          nickName :this.formLabelAlign.user_name,
          avatar:this.formLabelAlign.user_avthor,
          phonenumber:this.formLabelAlign.user_phone,
          sex:this.formLabelAlign.radio1 ,
          dateBirth:this.formLabelAlign.data_value1 ,
          city:this.formLabelAlign.user_city,
          status:this.formLabelAlign.user_value,
          userWechat:this.formLabelAlign.wx ,
          UserId : this.UserId,
          studentId :this.studentId

        }
      // 传回给服务器
      updateUsetInfoApi(this.formUser).then(res =>{
          console.log(res,'asd');
      })
      console.log(this.formUser,'12312');


      this.formShow = false;
    },




    // 优势亮点打开
    openTwo(){
      // this.advan_textarea_v = this.Advantage_form.advan_textarea
        this.formShow2=true
    },
    // 优势亮点确认事件
    get_formShow2() {
        // 不能为空
        if(!this.advan_textarea_v){
          alert('请填写内容')
          return
        }
        // 判断是否是编辑模式
        if(this.Advantage_change){
            // 修改原数组
            this.Advantage_form_list[this.Advantage_ChangeIndex] = this.advan_textarea_v
            // 改变状态
            this.Advantage_change = false
            // 合并数组发送给服务器
            let arr = this.Advantage_form_list.reduce((item,sum) =>{
              item += '-'
              item += sum
              return item
            })
            this.Advantage_form.advan_textarea = arr
             updateUserAdvantageApi(this.Advantage_form.advan_textarea).then(res =>{
                console.log(res);
              })

        }else{
            // 如果不是修改状态直接添加
            if(this.Advantage_form_list.length>=3){
              alert('优势亮点最多添加三条哦')
              return
            }
             this.Advantage_form_list.push(this.advan_textarea_v)
            let arr = this.Advantage_form_list.reduce((item,sum) =>{
              item += '-'
              item += sum
              return item
            })
            this.Advantage_form.advan_textarea = arr
             updateUserAdvantageApi(this.Advantage_form.advan_textarea).then(res =>{
                console.log(res);
              })
            console.log(this.Advantage_form.advan_textarea,'vvv');
            console.log(this.Advantage_form_list,'list');
            // this.getUserInfoAdv()
        }

        // // console.log(spot)
        // // 传回给服务器
        // updateUserAdvantageApi(spot).then(res =>{
        //   console.log(res);
        // })
      this.advan_textarea_v = ''
      this.formShow2 = false;
    },

    // 优势亮点编辑
    Advantage_bianji(index){
      this.advan_textarea_v = this.Advantage_form_list[index]
      this.Advantage_change = true
      this.Advantage_ChangeIndex = index
      this.formShow2 = true

    } ,

    // 求职期望确认事件
    get_formShow3() {
       // 期望职位长度在3-15字符
      if(this.expect_form.expectJob.length>15 || this.expect_form.expectJob.length == 0){
        alert('期望职位长度在3-15字符')
        return
      }
      // 必须填写期望薪资
      console.log(this.expectMoneyOne,this.expectMoneyTwo);
      if((this.expectMoneyOne.length==0 || this.expectMoneyOne.length>4) || ( this.expectMoneyTwo.length ==0 || this.expectMoneyTwo.length>4)){
        alert('请填写期望薪资并且数额不能大于4')
        return
      }

      // 都确定好之后判断是否编辑模式
      if(this.expect_change){
        // 如果是修改模式的逻辑
        console.log(this.expect_list[this.expect_ChangeIndex],'1212');

        // 向服务器发送
        editUserexpectApi(this.expect_list[this.expect_ChangeIndex]).then(res=>{
            console.log(res);
             this.getUserInfoexpect()
        })
        this.expect_list[this.expect_ChangeIndex] = this.expect_form
      }else{
        // 如果不是修改模式的逻辑
        if(this.expect_list.length>=3){
          alert('最多添加三个哦')
          return
        }
        // console.log(this.expect_list,'111111111')
        this.expect_form.expectPay = `${this.expectMoneyOne}k---${this.expectMoneyTwo}k`
        let expectApi = {
          expectJob : this.expect_form.expectJob, // 期望职位
          expectIndutry : this.expect_form.expectIndutry, // 期望行业
          expectJobAddress : this.expect_form.expectJobAddress, // 期望地点
          expectPay : this.expect_form.expectPay, // 期望薪资范围
          expectMark : Number(this.expect_form.expectMark),
          expectStatus : Number(this.expect_form.expectMark) == 0?1:Number(this.expect_form.expectMark),
        }
        updataUserexpectApi(expectApi).then(res=>{
          console.log(res);
           this.getUserInfoexpect()
            this.expect_list.push(this.expect_form)
        })
        console.log(this.expect_form,'添加求职期望');
       
      }
     
      this.expect_change = false
      this.expect_form = {}
      this.formShow3 = false;
    },



    // 工作经历确认事件
    get_formShow4() {
       // 公司名称长度在3-15字符
      if(this.work_form.practiceLtd.length>15 || this.work_form.practiceLtd.length == 0){
        alert('公司名称长度在3-15字符')
        return
      }

      //职位名称长度在2-10字符
      if(this.work_form.positionName.length>15 || this.work_form.positionName.length == 0){
        alert('职位名称的长度在2-15字符')
        return
      }

       // 时间
      this.work_form.practiceStartTime = this.work_date [0] 
      // this.work_date[1];
      this.work_form.practiceMoveTime = this.work_date[1]

      console.log(this.work_form.practiceStartTime,this.work_form.practiceMoveTime,'工作精力时间');
      
      // 都确定好之后判断是否编辑模式
      if(this.work_change){
        // 如果是修改模式的逻辑


        edittUserWork(this.work_form).then(res =>{
          this.work_list[this.work_ChangeIndex] = this.work_form
          this.getUserInfoWork()
        })

        
      }else{
        // 如果不是修改模式的逻辑
        appendUserWork(this.work_form).then(res =>{
          this.work_list.push(this.work_form)
          this.getUserInfoWork()
        })
      }
      this.work_change = false
      this.work_form = {}
      this.work_date = ''
      this.formShow4 = false;
      
    },


    // 项目经历确认事件
    get_formShow5() {
       // 验证项目名称长度在3-15字符
      if(this.project_form.projectName.length>15 || this.project_form.projectName.length == 0){
        alert('项目名称长度在3-15字符')
        return
      }
      //项目职务长度在2-10字符
      if(this.project_form.projectPosition.length>15 || this.project_form.projectPosition.length == 0){
        alert('项目职务的长度在2-15字符')
        return
      }
      // 日期必须加上
       if(this.project_form.projectDate.length == 0){
        alert('请添加日期')
        return
      }
       // 时间
      this.project_form.projectDate = this.project_form.projectDate[0]+'---'+this.project_form.projectDate[1];
      console.log(this.project_form.projectDate)
      // 上面都验证好了之后开始判断是否编辑模式  然后开始修改 或者  开始添加
      if(this.project_change){
        // 如果是修改模式的逻辑

        editUseProject(this.project_form).then(res =>{
          console.log(res)
           this.project_list[this.project_ChangeIndex] = this.project_form
           this.getUserInfoObject()
        })
        console.log(this.project_form);
      }else{
        // 如果不是修改模式的逻辑
        console.log(this.project_form,'项目经历');

        appendUserProject(this.project_form).then(res =>{
            this.project_list.push(this.project_form)
            this.getUserInfoObject()
        })
        
      }
      
      this.project_change = false
      this.project_form = {}

      this.formShow5 = false;
    },


    // 教育经历确认事件
    get_formShow6() {
      // 验证学校名称长度在3-15字符
      if(this.edu_form.schoolName.length>15 || this.edu_form.schoolName.length == 0){
        alert('学校名称长度在3-15字符')
        return
      }
      // 验证专业名称的长度在2-15字符
      if(this.edu_form.schoolMajorName.length>15 || this.edu_form.schoolMajorName.length == 0){
        alert('专业名称的长度在2-15字符')
        return
      }
       // 必须填写时间
      if(this.edu_form.schoolEduTime.length == 0){
        alert('请填写在校时间')
        return
      }
      // 时间
      this.edu_form.schoolEduTime = this.edu_form.schoolEduTime[0]+'---'+this.edu_form.schoolEduTime[1];
      // 上面都验证好了之后开始判断是否编辑模式  然后开始修改 或者  开始添加
      if(this.study_change){
        // 如果是修改模式的逻辑
        editUserStudy(this.edu_form).then(res =>{
             this.edu_list[this.study_ChangeIndex] = this.edu_form
             this.getUserInfoStudy()
        })
       
      }else{
        // 如果不是修改模式的逻辑
        console.log(this.edu_form);
        appendUserStudy(this.edu_form).then(res =>{
            this.edu_list.push(this.edu_form)
            this.getUserInfoStudy()
        })
        
      }
      this.study_change = false
      this.edu_form = {}
      this.formShow6 = false;
      this.study_ChangeIndex = 0
    },


    // 资格证书确认事件
    get_formShow7() {
      // 如果输入长度不是1-10
      if(this.Certificate_form.Cer_input.length>11 || this.Certificate_form.Cer_input.length == 0){
        alert('资格证书字符长度1-10')
        return
      }
     
      // 如果不为空就向数组里面添加并且清除内容
      if(this.Cer_list.length-1 <= 30){
        this.Cer_list.push(this.Certificate_form.Cer_input)
        updataUserhonor(this.Cer_list).then(res =>{
            this.getUserinfoHoner()
        })
        
      }else{
        alert('资格证书最多存储30个哦')
      }
      this.Certificate_form.Cer_input = ''
       this.formShow7 = false;
    },


    // 技能标签确认事件
    get_formShow8() {
      // 如果输入长度大于10就返回
      if(this.skill_form.skill_input.length>16 || this.skill_form.skill_input.length == 0 ){
        alert('技能标签字符长度1-15')
        return
      }
      
       // 如果不为空并且数组最长小于10就向数组里面添加并且清除内容
      if(this.skill_list.length-1 <=10){
        this.skill_list.push(this.skill_form.skill_input)
       updataUserSkill(this.skill_list).then(res =>{
        this.getUserinfoSkill()
       })
      }else{
        alert('技能标签最多存储10给哦')
      }
      this.skill_form.skill_input = ''
      this.formShow8 = false;
    },



    // 语言能力确认事件
    get_formShow9() {
      // 如果语言能力长度输入就返回
      if(this.lang_form.lang_input.length>10 || this.lang_form.lang_input.length == 0 ){
        alert('语言能力输入字符长度1-10')
        return
      }
     

        // 如果不为空并且数组最长小于5就向数组里面添加并且清除内容
      if(this.lang_form.lang_list.length-1 <=5){
        this.lang_form.lang_list.push(this.lang_form.lang_input)
      }else{
        alert('语言能力最多存储五个哦')
      }
      this.lang_form.lang_input = ''
       this.formShow9 = false;
    },

    // 附加信息确认事件
    get_formShow10() {
      let form = new FormData()
      form.append("str",this.additional_form.additional_textarea)
      updataUserappendix(form).then(res =>{
        this.getUserInfoappendix()
      })
      this.formShow10 = false;
    },


    // 个人作品点击确认事件
    get_formShow11() {
      // 开始判断作品名称   字符在1-15之间
      if(this.personwork_form.opusName.length == 0 || this.personwork_form.opusName.length >16){
        alert('作品名称字符长度1-15')
        return
      }
      // 判断链接是否符合格式
      const reg = /^(http|https):\/\/[^\s/$.?#].[^\s]*$/
      if(!reg.test(this.personwork_form.opusUrl)){
        // 如果不正确的话
        alert('请输入正确的链接地址,前缀是http/https')
        return
      }
      // 作品描述不能为空
      if(!this.personwork_form.opusText){
        alert('请输入作品描述')
        return
      }

      // 都正确了之后   判断是否是编辑模式   然后开始修改  或者   开始添加
      if(this.isChange){
          editUserOpusListByUserId(this.personwork_form).then(res =>{
            this.personwork_form_list[this.ChangeIndex] = this.personwork_form
            this.getUserInfoopus()

          })
      }else{
        // 如果不是修改状态
        updataUserOpusListByUserId(this.personwork_form).then(res =>{
          this.personwork_form_list.push(this.personwork_form)
          this.getUserInfoopus()
        })
      }
      this.personwork_form = {}
      
      this.formShow11 = false;
      // 关闭之后改变状态
      this.isChange = false
      this.ChangeIndex = 0
    },


    //  项目经历 点击确定时添加列表
    get_projectitems(id) {},


    fileChange(e) {
      let fileName = e.target.files[0].name; // 获取选定的文件名
      //  console.log(e.target)
      console.log(fileName);
    },

    // 清除span事件
    clear_span(item,index,it){
      if(it== '荣誉证书'){
         item.splice(index,1)
        updataUserhonor(this.Cer_list).then(res =>{
           this.getUserinfoHoner()
        })
        
      }if(it == '技能标签'){
        item.splice(index,1)
        updataUserSkill(this.skill_list).then(res =>{
          this.getUserinfoSkill()
        })
        
      }
     
    },

    // 统一删除事件    接受三个值：  要删除的数据所在的数组    下标 要调用哪个接口
    delList(item,index,it){
     if(it == '期望'){
       popUserexpectApi(item[index].expectId).then(res=>{
        this.getUserInfoexpect()
        item.splice(index,1)
      })
     }else if(it == '工作经历'){
        popUserWork(item[index].practiceId).then(res =>{
          this.getUserInfoWork()
         item.splice(index,1)
        })
     }if(it == '项目经历'){
      updateUserProject(item[index].projectId).then(res =>{
        this.getUserInfoObject()
        item.splice(index,1)
      })
     }if(it == '教育经历'){
      updateUserStudy(item[index].schoolId).then(res =>{
         this.getUserInfoStudy()
        item.splice(index,1)
      })
     }if(it == '个人作品'){
      popUserOpusListByUserId(item[index].idOpus).then(res =>{
        this.getUserInfoopus()
        item.splice(index,1)
      })
     }if(it == '优势亮点'){
        item.splice(index,1)
        console.log(this.Advantage_form_list,'list');
      // 合并数组发送给服务器

       if(!this.Advantage_form_list.length == 0){
         let arr = this.Advantage_form_list.reduce((item,sum) =>{
          item += '-'
          item += sum
          return item
        })
        this.Advantage_form.advan_textarea = arr
       }else{
        this.Advantage_form.advan_textarea = ''
       }
        console.log(this.Advantage_form.advan_textarea,'text');
        updateUserAdvantageApi(this.Advantage_form.advan_textarea).then(res =>{
        })
        // this.getUserInfoAdv()
     }
      
    },

    // 统一编辑事件    接受六个值：  对象  要修改的数据所在的数组 下标  要改变的修改状态 修改状态下下标  要显示的item的数字
    bianjiList(item,itemlist,change,changeIndex,index,showIndex){  
      this[change] = true // 改变修改状态
      this[changeIndex] = index // 改变修改数组的下标
      this[item] = {...itemlist[index]}  // 赋值
      // 打开
      this[`formShow${showIndex}`] = true
    },


    // 统一取消事件   接受三个值    要关闭的item下标   和  要清空的对象   还有取消的是哪个
    closeBtn(index,item,change){
      this[index] = false
      this[item] = {}
      this[change] = false
      console.log(this.personwork_form)

    },
  },
  computed:{
    PhoneNumber() {
          // 截取前三位和后四位，并用'****'替换中间部分  
          return this.formLabelAlign.user_phone.slice(0, 3) + '****' + this.formLabelAlign.user_phone.slice(-4);  
      }  
  },
  
  
};
</script>

<style scoped lang ='less'>
  @import url('@/assets/userCss.css');
</style>