<template>
    <div class="Userfrom">
         <el-container>
            <el-header  class="el-headee"  style="
                                    position: fixed;
                                    left: 0px;
                                    top: 0px;
                                    width: 100%;
                                    z-index: 10">
                <!-- 顶部导航栏 -->
                <BodyNavTop class="el-headerr"></BodyNavTop>
            </el-header>
            <el-main style="margin-top:68px;">
                <!-- 下部编辑信息 -->
                <UserContent></UserContent>
            </el-main>
        </el-container>
    </div>
</template>

<script>
import BodyNavTop from '@/components/BodyNavTop.vue'
import UserContent from '@/components/userinform/UserContent.vue'

import '../../../publicCss/publicCss.css'

export default {
       components:{
        BodyNavTop,
        UserContent
    }
}
</script>

<style>
    .el-main{
        overflow: visible;
    }
</style>