<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import { getToken } from './utils/auth';

export default {
  name: 'App',
  components: {
    
  },
  data(){
    return{
      isReLoad:getToken() === null ? false : true
    }
  },
  created(){
    this.initWebSocket()
  },
  mounted(){
        //监听发送过来的消息
        window.addEventListener("onmessageWS", this.getSocketData);
  },
  methods:{
    initWebSocket(){
      if(getToken() != null){
        this.$websocket.initWebSocket();
      }
    },
    getSocketData(e){
        this.$notify({
          title: '消息',
          message: '接收到对方给您发的消息',
          type: 'success'
        });
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
