<template>
    <div class="LoginIn">
        <div class="Login-content">
            
            <!-- 登录form组件 -->
            <LoginForm v-show="isLoginFormshow" :re='request'></LoginForm>
            <!-- 注册form组件 -->
            <RegisterForm v-show ="isRegisterFormshow" :re='request'></RegisterForm>
            <!--  阅读协议 -->
             <div class="protocol">
                <div class="proto">
                    <div class="proto-check">
                        <input class="proto-check-input" type="checkbox" id="agree" name="agree" required v-model="request">
                    </div>
                    <div class="proto-a">
                        <span>同意</span>
                        <a @click='re_show' href="javascript:;">《xx用户服务协议》</a>
                        及
                        <a @click = 'rr_show' href="javascript:;">《个人信息保护政策》</a>
                    </div>
                </div>
             </div>
            
        </div>
      
    </div>
</template>

<script>
import LoginForm from '@/components/Logion/xifen/LoginForm.vue'
import RegisterForm from '@/components/Logion/xifen/RegisterForm.vue'

// 引入事件总线
import LoginBus from '@/utils/LoginBus'
export default {
    data(){
        return{
            isLoginFormshow:true,
            isRegisterFormshow:false,

            //  是否同意各种协议
            request:false
        }
    },
    methods:{
        re_show(){
            this.$router.push('/ush')
        },
        rr_show(){
            this.$router.push('/us')
        }
    },
    components:{
        LoginForm,
        RegisterForm
    },
    created(){
        LoginBus.$on('reg',(reg)=>{
            // console.log(reg,'reg')
            this.isRegisterFormshow = reg
            this.isLoginFormshow = !reg
            
        })
        LoginBus.$on('login',(login)=>{
            console.log(login,'login')
        })
    }
}
</script>

<style scoped lang = 'less'>
.protocol{
    width: 100%;
    margin-top: 15px;
    .proto-check-input{
        cursor: pointer;
        margin-right: 4px;
    }
    .proto{
        width: 100%;
        display: flex;
        align-items: flex-start;
        
    }
    .proto-a a{
        color: #ff6400;
    }
    .proto-a a:hover{
        text-decoration:underline !important ;
    }
}

</style>