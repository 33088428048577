<template>
    <div class="MerBybom">
        <el-container>
            <el-main>
                <!-- 下半部分的左边 -->
                 <MerByleft></MerByleft>
            </el-main>
            <el-aside>
                 <!-- 下半部分的右边 -->
                 <MerByright></MerByright>
            </el-aside>
        </el-container>
    </div>
</template>

<script>
import MerByleft from '@/components/MerElement/xifen/MerBYleft.vue'
import MerByright from '@/components/MerElement/xifen/MerByright.vue'
export default {
   components:{
     MerByleft,
    MerByright
   }
}
</script>

<style scoped less = 'less'>
    .el-aside{
        width: 510px !important;
    }
    .el-aside{
        padding:20px;
    }
</style>
