import service from '@/utils/request'
import request from '@/utils/request'
export function getUserLeftList(){
    return request({
        url:'/api/message/getMessUserList',
        method:'POST',
        headers:{
            isToken:true
        }
    })
}

export function getUserMessInfo(serviceId){
    return request({
        url:'/api/message/getUserMessInfo',
        method:'POST',
        params:{"serviceId":serviceId},
        headers:{
            isToken:true
        }
    })
}

export function sendMessageInfo(sendMessageDto){
    return request({
        url:'/api/message/sendMessage',
        method:'POST',
        data:sendMessageDto,
        headers:{
            isToken:true
        }
    })
}

export function sendInterView(sendInterViewDto){
    return request({
        url:'/api/jobhunting/sendInterView',
        method:'post',
        data:sendInterViewDto,
        headers:{
            isToken:true
        }
    })
}