<template>
    <div class="Home">
        <el-container>
            <el-header >
                <!-- 导航栏上半部分容器 -->
                <IndexConTop></IndexConTop>
            </el-header>
            <el-main class="el-headerr" style="overflow: visible;">
                <!-- 导航栏下半部分容器 -->
                <IndexConBtm></IndexConBtm>
            </el-main>
        </el-container>
    </div>
</template>

<script>

// 注册组件
import IndexConTop from '@/components/index/IndexConTop.vue'   // 上本部分容器
import IndexConBtm from '@/components/index/IndexConBtm.vue'  // 下半部分容器

import '../../../publicCss/publicCss.css'
export default {
    components:{
        IndexConTop,
        IndexConBtm
    }
}
</script>

<style scoped>

    .el-header{
        padding: 0;
        height: auto !important;
    } 
</style>