import request from "@/utils/request"

// 项目经历获取
export function getUserProject(){
    return request({
        url:'/api/project/getProject',
        method:'GET',
        headers:{
            isToken:true
        }
    })
}
// 项目经历编辑
export function editUseProject(userInfo){
    return request({
        url:'/api/project/updateProject',
        method:'POST',
        data:userInfo,
        headers:{
            isToken:true
        }
    })
}

// 项目经历添加
export function appendUserProject(addd){
    return request({
        url:'/api/project/sendProject',
        method:"POST",
        data:addd,
        headers:{
            isToken:true
        }
    })
}

// 项目经历删除
export function updateUserProject(userAdvant){
    return request({
        url:`/api/project/delProject?projectId=${userAdvant}`,
        method:'DELETE',
        headers:{
            isToken:true
        }

    })
}