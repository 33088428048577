<template>

    <div class="userchat">
        <div class="el-headee"  style="
                                    position: fixed;
                                    left: 0px;
                                    top: 0px;
                                    width: 100%;
                                    z-index: 10">
            <BodyNavTop class="el-headerr"></BodyNavTop>
        </div>
        <div class="chat-con" style="margin-top:80px;">

            <div class="chat-list">
                <div class="list-content">
                    <div class="list-input">
                        <input type="text" class="chat-list" name="chat-list" placeholder="搜索30天内的联系人">
                        <i class="el-icon-search"></i>
                    </div>
                    <div class="lists">
                        <ul class="list-ul">
                            <!-- 要渲染的li -->
                            <!-- 左侧好友列表 -->
                            <li class="list-items" v-for="(item,index) in leftList" :key="index" @click="selectFrom(item)">
                                <el-badge :value="item.statusNumber === 0 ? null : item.statusNumber" >
                                    <div class="merInform">
                                        <div class="icon">
                                            <el-avatar :size="50" :src="item.avatar === '' ? circleUrl : item.avatar"></el-avatar>
                                        </div>
                                        <div class="introduce">
                                            <div class="introduce-top">
                                                <div class="in-top-left">
                                                    <span class="merName">{{ item.nickName }}</span>
                                                    <!-- <span>|</span> -->
                                                    <span class="companyName"></span>
                                                    <!-- <span>|</span> -->
                                                    <span class="zhiwei"></span>
                                                </div>
                                            </div>
                                            <div class="introdecu-bottom">
                                                <p>最近的聊天</p>
                                            </div>
                                        </div>
                                    </div>
                                </el-badge>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <!-- 头部信息 -->
            <div class="chat-content">
                <div class="chat-content-con">
                    <div class="mer-top">
                        <div class="merform">
                            <span class="merName">{{ serviceName }}</span>

                        </div>
                    </div>
                    <!-- 主界面聊天 -->
                    <div class="chatAndmer">
                        <div class="interface">
                            <ul class="inter-ul" v-for="(item,index) in messInfo" :key="index">
                                <li class="friend" v-if="!item.me">
                                    <div class="figure">
                                            <el-avatar :size="50" :src="item.avatar === '' || item.avatar === null ? circleUrl : item.avatar"></el-avatar>
                                    </div>
                                    <div class="friend-text">
                                        <div class="flex">
                                            <div class="text">
                                                <p>
                                                    <span>{{ item.message }}</span>
                                                </p>
                                            </div>
                                        </div>
                                        <span style="font-size:10px;margin-left:10px">{{ item.createTime }}</span>
                                   </div>
                                </li>
                                <li class="myself" v-else>
                                    <div class="myself-text">
                                        <p>{{ item.message }}</p>
                                    </div>
                                    <div style="font-size:10px;text-align: right; ">{{ item.createTime }}</div>
                                
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="submit-text">
                        <div class="submit-div">
                            <div class="submit-top">
                                <div class="sum-image">
                                    <!-- 发送简历 -->
                                    <!-- <i class="el-icon-picture-outline" @click="sendInterViewInfo()"></i> -->
                                </div>
                            </div>
                            <div class="submit-content">
                                <div @input="updateInputContent"  
                                    :innerText="inputContent"
                                    class="input-content" 
                                    contenteditable='true'>
                                </div>
                            </div>
                        </div>
                          <div class="submit-bottom" style="margin-top:-40px;">
                                <el-button @click="sendMessage" >发送</el-button>
                            </div>
                    </div>
                </div>
            </div>

        </div>

    </div>
</template>

<script>
import { getUserLeftList,getUserMessInfo,sendMessageInfo } from '@/api/message';

// import '../../../publicCss/publicCss.css'
// import '@/assets/UserChat.css'
import BodyNavTop from '@/components/BodyNavTop.vue'
export default {
    components:{
            BodyNavTop
        },
    data(){
        return{
            circleUrl: "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
            inputContent: '' ,
            message:{
                toUserName:'',
                message:'',
            },
            leftList:[],
            messInfo:[],
            serviceName:'',
            userInfo:{},
        }
    },
    created(){
        this.getUserLeft()
    },
    mounted(){
        window.addEventListener("onmessageWS", this.getSocketData);
    },
    methods:{
        //监听数据发送过来
        getSocketData(){
            this.$notify({
                title: '消息',
                message: '接收到对方给您发的消息',
                type: 'success'
            });
            this.getUserLeft()
            this.selectFrom(this.userInfo)
        },
        updateInputContent(event) {  
            this.inputContent = event.target.innerText;
        },
        getUserLeft(){
            getUserLeftList().then(res => {
                this.leftList = res.data.data
                //将发送消息中的from来自填上自己
                this.message.fromUserName = localStorage.getItem('pc_user_name')
            })
            
        },
        selectFrom(item){


            this.userInfo = item
            this.message.toUserName = item.userId
            
            this.serviceName = item.nickName
            //这里从后端获取当前用户和选择的用户聊天记录
            /**
             * 返回结构：
             * {
                    avatar:'',
                    message:'',
                    isMe:null,
                    createTim:null
                }
             */
            getUserMessInfo(item.userId).then(res => {
                this.messInfo = res.data.data
            })
            this.getUserLeft()
        },
        sendMessage(){
            this.message.message = this.inputContent
            //给后端发送数据
            if(this.message.toUserName == null || this.message.toUserName == ''){
                this.$message({
                    type:'error',
                    message:'请选择列表用户'
                })
                return;
            }
            if(this.inputContent == '' || this.inputContent == null){
                this.$message({
                    type:'error',
                    message:'请勿发送空数据'
                })
            }
            sendMessageInfo(this.message).then(res => {
                if(res.data.code === 200){
                    this.getUserLeft()
                    this.selectFrom(this.userInfo)
                }
            })
            //清除数据
            document.querySelector(".submit-content>div").innerText = ''
            this.inputContent = ''
        }
    }
}
</script>

<style scoped lang = 'less'>
    // @import url('@/assets/UserChat.css');
    .chat-con{
        max-width: 1184px;
        min-height: 625px;
        margin: 0 auto;
        border-radius: 20px;
        display: flex;
        justify-content: space-between;
        margin-top: 50px;
        >div{
            border-radius: 20px;
            background-color: white;
        }
        .chat-list{
            padding: 10px;
            position: relative;
            box-sizing: border-box;
            width: 423px;
            .list-input{
                input{
                    width: 100%;
                    height: 30px;
                    background: #fff;
                    border-radius: 4px;
                    border: 1px solid #e0e0e0;
                    padding: 0 12px;
                    font-size: 13px;
                    line-height: 30px;
                }
                input:hover{
                    border: 1px solid cyan;
                }
                input:focus{
                    border: 0px;
                    outline: none;
                }
                i{
                    position: absolute;
                    right: 17px;
                    top: 17px;
                    font-size: 14px;
                     color: #9fa3b0;
                }
            }
            .lists{
                margin-top: 10px;
                .list-ul{
                    max-height: 570px; 
                    overflow-y: auto; 
                    padding: 0; 
                    list-style: none; 
                    margin: 0;   
                    width: 410px;
                    .list-items{
                        
                        padding: 10px;
                        border-radius: 10px;
                        margin: 10px 0px 10px 0px;
                        cursor: pointer;
                        transition: all 0.2s;
                        .merInform{
                            display: flex;
                            align-items: center;
                            justify-content: left;
                        }
                        .introduce{
                            width: 100%;
                            margin-left: 2px;
                        }
                        .introduce-top{
                            margin-bottom: 6px;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            
                           .in-top-left{
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            display: flex;
                            align-items: center;
                            .companyName{
                                overflow: hidden;  
                                text-overflow: ellipsis;  
                                white-space: nowrap; 
                                width: 147px;
                            }
                        }
                        span{
                                display: inline-block;
                                margin: 0px 2px;
                                font-size: 15px;
                            }
                        }
                        .introdecu-bottom{
                            text-align: left;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            font-size: 13px;
                            padding-right: 30px;
                            width: 248px;
                        }
                    }
                    .list-items:hover{
                        background: #b9b9b9;
                    }
                }
                .list-ul::-webkit-scrollbar {  
                    width: 5px; 
                } 
                .list-ul::-webkit-scrollbar-track {  
                    background: #f1f1f1; /* 轨道的背景色 */  
                } 
                .list-ul::-webkit-scrollbar-thumb {  
                    background: #afafaf; /* 滚动条的颜色 */  
                }
             
                
            }
        }
        .chat-content{
            width: 750px;
            padding: 10px;
            margin-left: 12px;
        }
        .chat-content{
            .chat-content-con{
                .mer-top{
                    display: inline-block;

                    display: flex;
                    justify-content: left;
                    padding: 10px;
                    border-bottom: 1px solid #efefef;
                    span{
                        margin: 0px 4px;
                    }
                    .companyName{
                        overflow: hidden;  
                        text-overflow: ellipsis;  
                        white-space: nowrap; 
                        width: 247px;
                    }
                }
                .chatAndmer{
                    height: 400px;
                    .interface{
                        position: relative;
                        box-sizing: border-box;
                        width: 100%;
                        height: 100%;
                        overflow-y:scroll ;
                        .inter-ul{
                            padding: 0; 
                            list-style: none; 
                            margin: 0;   
                            padding: 10px 10px 0px 10px;
                            >li{
                                margin: 5px 0px;
                            }
                            .chat-time{
                                font-size: 12px;
                                color: #696969;
                            }
                            .myself{
                                overflow: hidden;
                                .myself-text{
                                    float: right;
                                    color: #333;
                                    border-top-right-radius: 0;
                                    background-color: rgba(0,190,189,.2);
                                    line-height: 22px;
                                    position: relative;
                                    max-width: 420px;
                                    padding: 8px 12px;
                                    vertical-align: top;
                                    word-break: break-all;
                                    border-radius: 8px;
                                }
                            }
                            .friend{
                                display: flex;
                                justify-content: left;
                                align-items: center;
                                .friend-text{
                                    display: inline-block;
                                }
                                .flex{
                                    margin-left: 2px;
                                    display: flex;
                                    justify-content: left;
                                    align-items: center;
                                    line-height: 22px;
                                    position: relative;
                                    max-width: 420px;
                                    padding: 8px 12px;
                                    vertical-align: top;
                                    word-break: break-all;
                                    border-radius: 8px;
                                    background-color: #f8f8f8;
                                    color: #333;
                                }
                            }
                        }
                    }
                    .interface::-webkit-scrollbar {  
                        width: 5px; 
                    } 
                    .interface::-webkit-scrollbar-track {  
                        background: #f1f1f1; /* 轨道的背景色 */  
                    } 
                    .interface::-webkit-scrollbar-thumb {  
                        background: #afafaf; /* 滚动条的颜色 */  
                    }
                }
                .submit-text{
                    border-top: 1px solid #e1e1e1;
                    height: 160px;
                }
            }
            .submit-text{
                .submit-div{
                    .submit-top{
                        height: 30px;
                        display: flex;
                        justify-content: left;
                        padding-left: 4px;
                        align-items: center;
                        .sim-image{
                            font-size: 23px;
                            cursor: pointer;
                            color: #7f7f7f;
                        }
                    }
                    .submit-content{
                        .input-content{
                            height: 110px;
                            overflow-y: scroll;
                            text-align: left;
                            padding: 4px;
                            box-sizing: border-box;
                            outline: none;
                        }
                    }
                    .input-content:focus{
                        border: none;
                        outline: none;
                    }
                    .input-content::-webkit-scrollbar {  
                        width: 2px; 
                    } 
                    .input-content::-webkit-scrollbar-track {  
                        background: #f1f1f1; /* 轨道的背景色 */  
                    } 
                    .input-content::-webkit-scrollbar-thumb {  
                        background: #afafaf; /* 滚动条的颜色 */  
                    }
                }
                .submit-bottom{
                    text-align: right;
                    padding-right: 10px;
                    color: #c6c6c6;
                }
            }
        }
}


</style>