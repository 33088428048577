<template>
  <div class="Pri">
    <el-container>
      <el-header class="el-headee"  style="
                                    position: fixed;
                                    left: 0px;
                                    top: 0px;
                                    width: 100%;
                                    z-index: 10">
        <BodyNavTop class="el-headerr"></BodyNavTop>
      </el-header>
      <el-main style="margin-top:60px;">
        <div class="pr-bottom">
          <div class="pr-left">
            <ul class="list-ul">
              <li class="active">账号设置</li>
              <!-- <li>账号设置</li> -->
              <!-- <li>密码设置</li>
              <li>我的身份</li>-->
            </ul>
          </div>
          <div class="pr-right">
            <!-- 
                
                手机号码  手机号码  手机号码  手机号码

            -->
            <div class="pr-item">
              <div class="it-title">手机号码</div>
              <div class="center">
                <p>
                  当前手机号：
                  <span class="now_phone">{{nowPhone}}</span>
                </p>
              </div>
              <div class="it-btn">
                <button class="btn" @click="show_phone">修改手机号</button>
              </div>
              <div class="show" v-show="zongShow">
                <div class="black">
                  <div class="yan" v-show="yanShow">
                    <div class="gong-top">
                      <span>验证当前手机号</span>
                      <span class="bi" @click="zongShow = false">×</span>
                    </div>
                    <div class="yan-content">
                      <div class="p">请完善下面手机号，确认是您本人操作</div>
                      <div class="zheng">
                        <div class="head hh">{{phoneHead}}</div>

                        <div class="input">
                          <input
                            type="text"
                            maxlength="1"
                            v-model="input1"
                            @input="handleInput('input1', 'input2')"
                            ref="input1"
                          />
                          <input
                            type="text"
                            maxlength="1"
                            v-model="input2"
                            @input="handleInput('input2', 'input3')"
                            ref="input2"
                          />
                          <input
                            type="text"
                            maxlength="1"
                            v-model="input3"
                            @input="handleInput('input3', 'input4')"
                            ref="input3"
                          />
                          <input
                            type="text"
                            maxlength="1"
                            v-model="input4"
                            @input="handleInput('input4', null)"
                            ref="input4"
                          />
                        </div>

                        <div class="foot hh">{{phoneFoot}}</div>
                      </div>
                    </div>
                    <div class="yan-bottom">
                      <div class="quxiao">
                        <button class="quxiao-btn" @click="zongShow = false">取消</button>
                      </div>
                      <div class="next">
                        <button
                          @click="next_btn()"
                          :disabled="!next_dis"
                          class="next-btn"
                          :class="{'btn-active':next_dis}"
                        >下一步</button>
                      </div>
                    </div>
                  </div>

                  <div class="gai" v-show="gaiShow">
                    <div class="gong-top">修改手机号</div>
                    <div class="yan-content">
                      <div class="p">修改成功后，简历和登录手机号会同步修改</div>
                      <div class="nnn">
                        <div class="new">
                          <span>手机号：</span>
                          <input
                            type="text"
                            v-model="newPhone"
                            :readonly="ischangephone"
                            placeholder="请输入新的手机号"
                          />
                        </div>
                        <div class="ma">
                          <span>验证码：</span>
                          <input type="text" v-model="ma" placeholder="请输入验证码" />
                          <span class="dengdai" v-if="countdown > 0">{{countdown}}秒之后再次获取</span>
                          <span class="huoqu" v-else @click="huoqu">获取验证码</span>
                        </div>
                      </div>
                    </div>
                    <div class="yan-bottom">
                      <div class="quxiao">
                        <button class="quxiao-btn" @click="changeph">取消</button>
                      </div>
                      <div class="confim">
                        <button class="confim-btn" @click="changePhone">确定</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- 

                我的邮箱  我的邮箱  我的邮箱  我的邮箱

            -->

            <div class="pr-item">
              <div class="it-title">我的邮箱</div>
              <div class="center">
                <p>
                  当前邮箱
                  <span class="now_phone">{{nowemail}}</span>
                </p>
              </div>
              <div class="it-btn">
                <button class="btn" @click="emailshow = true">修改邮箱</button>
              </div>
              <div class="show" v-show="emailshow">
                <div class="black">
                  <div class="gai">
                    <div class="gong-top">
                      <span>修改邮箱</span>
                      <span class="bi" @click="emailshow = false">×</span>
                    </div>
                    <div class="yan-content">
                      <div class="p">请输入新的邮箱，修改之后简历将会同步修改</div>
                      <div class="nnn">
                        <div class="new">
                          <span>新邮箱：</span>
                          <input
                            type="text"
                            v-model="newEmail"
                            :readonly="ischangeemail"
                            placeholder="请输入新的邮箱"
                          />
                        </div>
                        <div class="ma">
                          <span>验证码：</span>
                          <input type="text" v-model="email_ma" placeholder="请输入验证码" />
                          <span class="dengdai" v-if="countdown > 0">{{countdown}}秒之后再次获取</span>
                          <span class="huoqu" v-else @click="huoquemail">获取验证码</span>
                        </div>
                      </div>
                    </div>
                    <div class="yan-bottom">
                      <div class="quxiao">
                        <button class="quxiao-btn" @click="emailshow = false">取消</button>
                      </div>
                      <div class="confim">
                        <button
                          class="confim-btn"
                          :class="{'confim-active':!isDisabled}"
                          @click="changeemail"
                        >确定</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--

                    密码设置  密码设置  密码设置  密码设置

            -->
            <div class="pr-item">
              <div class="it-title">密码设置</div>
              <div class="center">
                <p>
                  当前账号
                  <span class="now_phone">{{nowPhone}}</span>
                </p>
              </div>
              <div class="it-btn">
                <button class="btn" @click="passshow = true">修改密码</button>
              </div>

              <div class="show" v-show="passshow">
                <div class="black">
                  <div class="gai">
                    <div class="gong-top">
                      <span>修改密码</span>
                      <span class="bi" @click="passshow = false">×</span>
                    </div>
                    <div class="yan-content">
                      <div class="nnn">
                        <div class="nick">
                          <span>手机号：{{nowPhone}}</span>
                        </div>
                        <div class="ma">
                          <span>验证码：</span>
                          <input type="text" v-model="pass_ma" placeholder="请输入验证码" />
                          <span class="dengdai" v-if="countdown > 0">{{countdown}}秒之后再次获取</span>
                          <span class="huoqu" v-else @click="huoqupass">获取验证码</span>
                        </div>
                        <div class="new aaaa">
                          <span>新密码：</span>
                          <div>
                            <input
                              type="text"
                              v-model="new_pass"
                              placeholder="长度8-20位,可以是字母,数字,下划线,不支持空格"
                            />
                            <input type="text" v-model="re_pass" placeholder="请确认密码" />
                            <p v-if="passwordError" class="error">{{ passwordError }}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="yan-bottom">
                      <div class="quxiao">
                        <button class="quxiao-btn" @click="passshow = false">取消</button>
                      </div>
                      <div class="confim">
                        <button class="confim-btn" @click="changepass">确定</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>



          </div>
        </div>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import BodyNavTop from "@/components/BodyNavTop.vue";

import '../../../publicCss/publicCss.css'
export default {
  components: {
    BodyNavTop
  },
  data() {
    return {
      // 控制验证的弹出显示
      zongShow: false,
      //   验证过程
      yanShow: true,
      //  最后更改
      gaiShow: false,
      inputHead: "",
      input1: "",
      input2: "",
      input3: "",
      input4: "",
      inputFoot: "",

      //   手机号
      oldPhone: "19543442972",

      //  新的手机号
      newPhone: "",
      // 验证码
      ma: "",
      // 控制获取状态
      countdown: 0,
      // 控制是否可以点击
      ischangephone: false,

      //    邮箱
      nowemail: "huochan88_hh@163.com",
      // 控制显示隐藏
      emailshow: false,
      // 邮箱验证码
      email_ma: "",
      // 新邮箱
      newEmail: "",

      isDisabled: false,

      // 是否可以修改
      ischangeemail: false,

      //    修改密码部分
      // 控制修改密码显示隐藏
      passshow: false,
      // 修改密码验证码
      pass_ma: "",
      // 新密码
      new_pass: "",
      // 确认密码
      re_pass: "",
      // 提示
      passwordError: "",



    //     修改身份部分
        // 身份
       shenfen:'学生',
        // 显示隐藏
        shenfenshow:false,
         // 身份
         identity: [
          { value: "在校生", label: "在校生" },
          { value: "应届毕业生", label: "应届毕业生" },
          { value: "实习生", label: "实习生" }
        ],
    };
  },
  methods: {
    // 手机号部分
    changeph(){
      this.zongShow = false,
      this.yanShow = true,
      this.gaiShow = false
    },
    show_phone() {
      this.zongShow = true;
      let xhr = setTimeout(() => {
        clearTimeout(xhr);
        this.$refs.input1.focus();
      }, 0);
    },

    handleInput(currentInput, nextInput) {
      if (this[currentInput].length >= 1 && nextInput) {
        this.$nextTick(() => {
          this.$refs[nextInput].focus();
        });
      }
    },
    //  下一步
    next_btn() {
      this.yanShow = false;
      this.gaiShow = true;
      console.log(this.yanShow, this.gaiShow);
    },

    // 检验手机号
    lookPhone() {
      // 验证手机号格式（以中国大陆手机号为例）
      const phoneRegex = /^1[3-9]\d{9}$/;
      if (!phoneRegex.test(this.newPhone)) {
        alert("请输入正确的手机号格式！");
        return false;
      } else {
        return true;
      }
    },

    // 获取手机验证码
    huoqu() {
      let lookphone = this.lookPhone();
      if (!lookphone) {
        return;
      }
      if (!this.isDisabled) {
        this.isDisabled = true;
        ischangephone = true;
        this.countdown = 60;
        const intervalId = setInterval(() => {
          if (this.countdown > 0) {
            this.countdown--;
          } else {
            this.isDisabled = false;
            clearInterval(intervalId);
          }
        }, 1000);
      }
    },

    // 确定提交手机号
    changePhone() {
      console.log("111");
    },

    // 修改邮箱部分
    changeemail() {
      console.log(123);
    },

    //  验证邮箱格式
    lookemail() {
      // 验证邮箱格式
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(this.newEmail)) {
        alert("请输入正确的邮箱格式！");
        return false;
      } else {
        return true;
      }
    },

    // 获取email
    huoquemail() {
      let looken = this.lookemail();
      if (!looken) {
        return;
      }
      if (!this.isDisabled) {
        this.isDisabled = true;
        this.ischangeemail = true;
        this.countdown = 60;
        const intervalId = setInterval(() => {
          if (this.countdown > 0) {
            this.countdown--;
          } else {
            this.isDisabled = false;
            clearInterval(intervalId);
          }
        }, 1000);
      }
    },
    // 修改密码部分

    lookPass() {
      // 验证密码和确认密码是否一致，且不为空
      if (!this.new_pass || !this.re_pass || this.new_pass !== this.re_pass) {
        alert("请输入密码，并确保两次输入的密码一致！");
        return false;
      } else {
        return true;
      }
    },

    checkpass() {
      // 密码格式
      const regex = /^[a-zA-Z0-9_]{8,20}$/;
      if (!regex.test(this.new_pass)) {
        this.passwordError =
          "密码长度8-20，且只能包含字母、数字和下划线，不支持空格";
        return false;
      } else {
        this.passwordError = "";
        return true;
      }
    },
    // 验证码
    huoqupass() {
      if (!this.isDisPass) {
        this.isDisPass = true;
        this.countdown = 60;
        const intervalId = setInterval(() => {
          if (this.countdown > 0) {
            this.countdown--;
          } else {
            this.isDisabled = false;
            clearInterval(intervalId);
          }
        }, 1000);
      }
    },
    changepass() {
      console.log(123);
      let checkpass = this.checkpass();
      if (!checkpass) {
        return;
      }

      let lookpass = this.lookPass();
      if (!lookpass) {
        return;
      }
    },

//      确认身份
    changeshenfen(){
        console.log(132);
        this.shenfenshow = false
    } 
  },
  computed: {
    // 隐藏中间四位
    nowPhone() {
      return this.oldPhone.replace(/(\d{3})\d{4}(\d{4})/, "$1****$2");
    },
    // 提取
    phoneHead() {
      this.inputHead = this.oldPhone.substring(0, 3);
      return this.oldPhone.substring(0, 3);
    },
    phoneFoot() {
      this.inputFoot = this.oldPhone.substring(this.oldPhone.length - 4);
      return this.oldPhone.substring(this.oldPhone.length - 4);
    },
    
    // 判断手机号是否正确
    next_dis() {
      const phone =
        this.inputHead +
        this.input1 +
        this.input2 +
        this.input3 +
        this.input4 +
        this.inputFoot;
      return phone === this.oldPhone;
    }
  }
};
</script>

<style lang = 'less' scoped>
.pr-bottom {
  width: 1080px;
  margin: 0 auto;
  background: white;
  display: flex;
  .pr-left {
    li {
      padding: 12px 100px 12px 40px;
      cursor: pointer;
      font-size: 16px;
      color: rgb(145, 145, 145);
      font-weight: 500;
    }
    li:hover {
      color: rgb(255, 94, 0);
    }
    .active {
      background: rgb(255, 244, 230);
      color: rgb(255, 94, 0);
    }
  }
  /* 公共的item样式 */
  .pr-right {
    border-left: 1px solid #efefef;;
    text-align: left;
    width: 81%;
    padding: 0px 9px;
    box-sizing: border-box;
    .pr-item {
      padding: 18px 20px;
      border-bottom: 1px solid #efefef;;
    }
    .pr-item:last-child{
      border-bottom: 0px;
    }
    .pr-item > div {
      margin: 10px 0px;
    }
    .center {
      font-size: 14px;
    }
    .it-btn > button {
      background: #ff6400;
      color: white;
      width: 133px;
      height: 35px;
      border-radius: 20px;
      border: none;
      margin-top: 28px;
      cursor: pointer;
    }
    .it-title {
      font-size: 18px;
      font-weight: 600;
    }
  }

  /* 
            修改手机的样式
        */
  .show {
    width: 100vw;
    height: 100vh;
    background: #98989870;
    position: absolute;
    left: 0px;
    top: 0px;
    margin: 0 !important;
    input {
      border: none;
    }
    input:focus {
      border: none;
      outline: none;
    }
    .black {
      width: 500px;
      border-radius: 10px;
      background: white;
      padding: 8px 10px;

      position: absolute;
      left: 50%;
      top: 30%;
      transform: translate(-50%, -30%);
      .yan-content {
        padding: 10px 0px;
        > div {
          margin: 10px 0px;
        }
        .p {
          font-size: 14px;
          color: #858585;
        }
        .zheng {
          display: flex;
          width: 320px;
          margin: 10px auto;
          align-items: center;
          justify-content: space-between;
          .hh {
            font-size: 20px;
          }
          .input > input {
            width: 40px;
            height: 40px;
            background: none;
            border-radius: 8px;
            margin: 0px 8px;
            box-shadow: 0px 0px 2px 0px #cbcbcb;
            font-size: 20px;
            text-align: center;
          }
        }
      }
    }

    /* 
            验证的公共样式
    */
    .gong-top {
      display: flex;
      justify-content: space-between;
      align-content: center;
      padding: 10px 0px;
      border-bottom: 1px solid rgb(188, 188, 188);
      span {
        display: block;
        line-height: 1rem;
      }
      span:first-child {
        font-size: 19px;
        color: black;
      }
      .bi {
        font-size: 24px;
        cursor: pointer;
      }
    }
    .yan-bottom {
      display: flex;
      justify-content: flex-end;
      button {
        cursor: pointer;
      }

      .quxiao-btn {
        background: none;
        border: 1px solid rgb(145, 145, 145);
        border-radius: 10px;
        width: 55px;
        height: 27px;
      }
      .next-btn {
        background: #dfdfdf;
        border-radius: 10px;
        border: none;
        width: 70px;
        height: 27px;
        margin-left: 10px;
        color: #757575;
        cursor: not-allowed;
      }
      .btn-active {
        background: rgb(255, 94, 0) !important;
        color: white !important;
        cursor: pointer !important;
      }
    }

    /*   更改样式 */
    .gai {
      .nnn {
        display: block;
        margin: 10px 0px;
        width: 100%;
        > div {
          margin: 14px 0px;
          span {
            font-size: 14px;
            font-weight: 600;
          }
          input {
            padding-left: 12px;
            box-sizing: border-box;
            border-radius: 8px;
            border: 1px solid #e7e7e7;
            font-size: 15px;
            height: 35px;
            width: 87%;
          }
        }
        .ma {
          position: relative;
          .huoqu {
            display: inline-block;
            position: absolute;
            right: 15px;
            top: 8px;
            cursor: pointer;
            color: #ff6400;
          }
          .dengdai {
            display: inline-block;
            position: absolute;
            right: 15px;
            top: 8px;
            cursor: pointer;
            color: rgb(145, 145, 145);
          }
        }
      }
      .confim {
        margin-left: 12px;
        button {
          background: #ff6400;
          border: none;
          border-radius: 10px;
          width: 55px;
          height: 27px;
          color: white;
        }
      }
    }
    .confim-active {
      cursor: not-allowed !important;
      background: #757575 !important;
    }
  }
}
.aaaa {
  span {
    margin-top: 15px;
  }
  p {
    color: red;
    font-size: 14px;
  }
  display: flex;
  input {
    margin: 10px 0px;
  }
}
.shenfen{
    font-weight: 600;
  }
</style>