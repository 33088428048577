import Vue from 'vue';  
import Vuex from 'vuex';  

Vue.use(Vuex);  
  
const store = new Vuex.Store({  
  state: {  
    paramList:{
      index:'home',
      titleSearch:''
    },
  },  
  getters: {  
    
  },  
  mutations: {  
    updateParamListIndex(state, newValue) {
      state.paramList.index = newValue; // 更新状态的 mutation
    },
    updateParamListTitleSearch(state, newValue) {
      state.paramList.titleSearch = newValue; // 更新状态的 mutation
    },
  },  
  actions: {  
    
  }  
});  
  
export default store;