<template>
    <div class="UserContent">
        <el-container>
            <!-- 左侧信息编辑 -->
            <el-main>
                <UserLeft style="position:relative;"></UserLeft>
            </el-main>
            <!-- 右侧辅佐填筑信息 -->
            <!-- <el-aside>
                <UserRight></UserRight>
            </el-aside> -->
        </el-container>
    </div>
</template>

<script>

import UserLeft from '@/components/userinform/xifen/UserLeft.vue'
import UserRight from '@/components/userinform/xifen/UserRight.vue'

export default {
    components:{
        UserRight,
        UserLeft
    }

}
</script>

<style scoped lang = 'less'>
  .el-main{
    min-width: 1468px;
    margin: 0 auto;
    max-width: 1580px;
   
  }
</style>