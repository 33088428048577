import request from "@/utils/request"

// 获取用户的个人作品
export function getUserOpusListByUserId(){
    return request({
        url:"/api/opus/getOpusList",
        method:"post",
        headers:{
            isToken:true
        }
    })
}

// 用户上传作品
export function updataUserOpusListByUserId(up){
    return request({
        url:"/api/opus/insertOpus",
        method:"post",
        data:up,
        headers:{
            isToken:true
        }
    })
}

// 用户修改作品
export function editUserOpusListByUserId(edit){
    return request({
        url:"/api/opus/updateOpus",
        method:"post",
        data:edit,
        headers:{
            isToken:true
        }
    })
}

// 用户删除作品
export function popUserOpusListByUserId(arr){
    return request({
        url:`/api/opus/delOpus?idOpus=${arr}`,
        method:"DELETE",
        headers:{
            isToken:true
        }
    })
}

