<template>
  <div class="BodyLeft">
        <!-- dody左边上面列表组件 -->
        <el-header>
            <BITop></BITop>
        </el-header>
        <!-- body左边下面的列表组件 -->
        <el-main>
            <BIBottom></BIBottom>
        </el-main>
  </div>
</template>

<script>
//  注册
import BITop from '@/components/index/xifen/BITop.vue'
import BIBottom from '@/components/index/xifen/BIBottom.vue'

export default {
    components:{
        BITop,
        BIBottom
    }
   
};
</script>

<style scoped lang = 'less'>

</style>