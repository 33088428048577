import request from "@/utils/request"

// 技能数组获取
export function getUserSkill(){
    return request({
        url:"/api/resumes/getSkills",
        method:"GET",
        headers:{
            isToken:true
        }
    })
}
// 添加技能标签
export function updataUserSkill(skill){
    return request({
        url:"/api/resumes/sendSkills",
        method:"POST",
        data:skill,
        headers:{
            isToken:true
        }
    })
}