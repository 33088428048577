import request from "@/utils/request"
// word模板获取
export function getUserWord(){
    return request({
        url:'/api/word/getWordList',
        method:'GET',
        headers:{
            isToken:true
        }
    })
}
// word模板下载
export function updataUserWord(word){
    return request({
        url:`/api/word/getWord?wordId=${word.wordId}&userId=${word.userId}`,
        method:'GET',
        headers:{
            isToken:true
        }
    })
    
}