<template>
    <div class="BITop">
        <!-- default-activ当前激活菜单的index -->
      <el-menu
        :default-active="activeIndex2"   
        class="el-menu-demo"
        mode="horizontal"
        text-color = 'black'
        @select="handleSelect"
        active-text-color="orange"
      >
      <!-- 

            后期要渲染的地方

       -->
        <el-menu-item index="1" style="font-size:22px;">推荐</el-menu-item>
        
        <!-- <el-menu-item index="2">
          地端
        </el-menu-item>
        <el-menu-item index="3">
          人端
        </el-menu-item>
        <el-menu-item index="4">
          就是不学前端
        </el-menu-item> -->
      </el-menu>
    </div>
</template>

<script>
export default {
     data(){
        return{
            activeIndex2: '1'
        }
    },
     methods: {
      handleSelect(key, keyPath) {
        console.log(key, keyPath);
      }
     }
}
</script>

<style scoped lang = 'less'>
</style>