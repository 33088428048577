<template>
    <div class="UserRight"></div>
</template>

<script>
export default {

}
</script>

<style scoped>
    
</style>