<template>
    <div class="MerBYleft">
             <!-- 左半部分的上边  商家信息 -->
            <el-header>
                <MrTop></MrTop>
            </el-header>
            <!-- 左部分的下边    公司信息-->
            <el-main style="padding: 20px;">
                <MrContent></MrContent>
            </el-main>
    </div>
</template>

<script>
import MrTop from '@/components/MerElement/xifen/MrTop.vue'
import MrContent from '@/components/MerElement/xifen/MrContent.vue'
export default {
    components:{
        MrTop,
        MrContent
    }
}
</script>

<style scoped lang = 'less'>
    .el-header{
        height: auto !important;
    }
</style>