import axios from 'axios';  
import store from '@/store/store'; 
import { getToken,removeToken } from './auth';
import { BASE_URL } from './basr_url';
import { Notification, MessageBox, Message, Loading } from 'element-ui'
import router from '@/router';
import { logout } from '@/api/login';

const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: BASE_URL,
  // 超时
  timeout: 10000
})
// 请求拦截器  
service.interceptors.request.use(config => {
    const isToken = (config.headers || {})['isToken'] === false
    // 预处理，比如添加token到headers中  
    // 假设token保存在Vuex的state中  
    if (getToken() && !isToken) {
      // 假设token保存在store的token字段中
      config.headers['Authorization'] = 'Bearer '+ getToken();
    } 
    return config;  
  },  
  error => {  
    // 处理请求错误  
    return Promise.reject(error);
  }  
);  
  
// 响应拦截器  
service.interceptors.response.use(  
  response => {  
    // 对响应数据进行处理  
    const code = response.data.code;
    if(code === 401){
      Message.error("登陆失效，请重新登录")
      logout().then(res => {
        //删除token缓存
        removeToken()
        //删除存储的名称和头像
        localStorage.removeItem('nickName')
        localStorage.removeItem('avatar')
        router.push('/')
        setTimeout(() => {
            location.reload()
        },1000) 
      })
      
    }
    if(code === 500){
      Message.error("服务器异常")
      // logout().then(res => {
      //   //删除token缓存
      //   removeToken()
      //   //删除存储的名称和头像
      //   localStorage.removeItem('nickName')
      //   localStorage.removeItem('avatar')
      //   router.push('/')
      //   setTimeout(() => {
      //       location.reload()
      //   },1000) 
      // })
    }
    return response;  
  },  
  error => {  
    // 处理响应错误，比如token过期、网络错误等  
    console.log('err' + error)
    let { message } = error;
    if (message == "Network Error") {
      message = "后端接口连接异常";
    } else if (message.includes("timeout")) {
      message = "系统接口请求超时";
    } else if (message.includes("Request failed with status code")) {
      message = "系统接口" + message.substr(message.length - 3) + "异常";
    }
    Message({ message: message, type: 'error', duration: 5 * 1000 })
    return Promise.reject(error)
  }  
);


export default service