import request from '@/utils/request'

export function sendEmail(email){
    return request({
        url:"/api/login/sendEmail",
        headers:{
            isToken:false
        },
        data:email,
        method:"POST"
    })
}



export function register(registerDto){
    return request({
        url:"/api/login/regiester",
        headers:{
            isToken:false
        },
        data:registerDto,
        method:"POST"
    })
}



