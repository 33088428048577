<template>
    <div class="MrT">
        <div class="Merchant">
            <div class="MerLeft">
                <div class="Merimg">
                    <el-avatar :size="50" :src="info.avatar != '' ? info.avatar : circleUrl"></el-avatar>
                </div>

                <div class="xinxi">
                    <div class="xin">
                        <span class="Mer">{{ info.nickName }}</span>
                        <span class="que">已认证</span>
                    </div>
                    <div class="xi">
                        <span class="Position">公司名称：</span>
                        <span class="xi-name">{{ info.deptName }}</span>
                    </div>
                </div>
            </div>

            <div class="comminicate">
                <el-button @click="getchat" type="warning" round>聊一聊</el-button>
                <el-button @click="sendInterViewInfo" type="warning" round>投递简历</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import { sendMessageInfo,sendInterView } from '@/api/message'
import { getToken } from '@/utils/auth'
export default {
    data(){
        return{
            circleUrl: "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
            info:[],
            message:{},
            sendInterViewDto:{}
        }
    },
    created(){
        this.getInfo()
    },
    methods:{
        sendInterViewInfo(){
            console.log(this.info)
            this.sendInterViewDto.userId = this.info.userId;
            this.sendInterViewDto.jobId = localStorage.getItem('prtId')
            if(!getToken()){
                this.$message({
                    message:"请先登录",
                    type:'error'
                })
                return;
            }
            //投递简历
            sendInterView(this.sendInterViewDto).then(res => {
                console.log(res)
                this.$message({
                    message:res.data.msg,
                    type:'success'
                })
            })
        },
        getInfo(){
            if(localStorage.getItem("prtInfo") == null || localStorage.getItem("prtInfo") == '') {
                this.$router.push("/")
            }
            this.info = JSON.parse(localStorage.getItem("prtInfo"))
        },
        getchat(){
            //先给这个人发送一条消息
            if(getToken() == null || getToken() == '') {
                this.$message({
                    type:"error",
                    message:"暂没有登陆，请登录！"
                })
                return;
            }
            this.message.message = "你好，看了贵司的招聘信息，跟我的履历非常匹配，可以聊聊吗"
            this.message.toUserName = this.info.userId
            console.log(this.info)
            sendMessageInfo(this.message).then(res => {})
            this.$CheckLoginStatic('/chat')
        }
    }
}
</script>

<style scoped lang = 'less'>

.MrT{
    width: 100%;
    .MerLeft{
        display: flex;
        align-items:center;
    }
    .Merchant{
        display: flex;
        padding: 40px;
        background: white;
        justify-content: space-between;
        align-items: center;
        
        .xinxi{
            margin-left: 14px;
            
            .xin{
                text-align: left;
                font-size: 18px;
                display: flex;
                justify-content: left;
                align-items: center;
                .que{
                        display: block;
                        width: 5.2rem;
                        height: -1.5rem;
                        text-align: center;
                        background: #ff6400;
                        color: white;
                        border-radius: 10px;
                        font-size: 1rem;
                        margin-left: 12px;
                }
               .Mer{
                 font-weight: 600;
               }
              
            }
            .xi{
                margin-top:6px;
                font-size: 14px;
                .xi-name{
                    margin-left: 12px;
                }
            }
        }
    }
}
    



    .el-button{
                    width: 133px;
                    height: 40px;
                    background: #ff6400;
                }
</style>