<template>
    <div class="CompanyBottom">
        <div class="content">
            <div class="position">

                <div class="title-one title">
                    <h3>职位</h3>
                     
                </div>

                <div class="list">
                    <!-- 需要渲染 -->
                    <div class="items" v-for="(item,index) in info.list" :key="index" @click="prtInfo(item)">
                        <div class="potitle">
                            <h4>{{ item.jobTitle }}</h4>
                            <span>{{ item.salaryRangeId }}</span>
                        </div>
                        <div class="workTime">
                            <span>{{ item.jobAddress }}</span>
                            <span>{{ item.jobTime }}</span>
                        </div>
                        <div class="Require">
                            <span v-for="i in item.requirements" :key="i">{{ i }}</span>
                        </div>
                    </div>
                </div>

                <div class="title-two title">
                    <h3>地址或联系信息</h3>
                </div>

                <div class="address">

                    <div class="addre">
                        <div class="addTitle">
                            <i class="el-icon-location-outline"></i>
                            <span>联系地址</span>
                        </div>
                        <div class="addcontent">
                            <p>{{ info.address == null ? '暂无此信息' : info.address }}</p>
                        </div>
                    </div>

                    <div class="email">
                       <div class="addTitle">
                             <i class="el-icon-message"></i>
                            <span>企业邮箱</span>
                       </div>
                       <div class="addcontent">
                            <p>{{ info.email == null ? '暂无此信息' : info.email }}</p>
                       </div>
                    </div>

                    <div class="iphone">
                        <div class="addTitle">
                            <i class="el-icon-phone-outline"></i>
                            <span>公司电话</span>
                        </div>
                        <div class="addcontent">
                            {{ info.phonenumber == null ? '暂无此信息' : info.phonenumber }}
                        </div>
                    </div>

                    <div class="web">
                        <div class="addTitle">
                            <i class="el-icon-monitor"></i>
                            <span>官网</span>
                        </div>
                        <div class="addcontent">
                           <a target="_blank" style="margin-left:-50px;" href="https://he1030.github.io/myblog/">https://he1030.github.io/myblog/</a>
                        </div>
                    </div>
                </div>


                 <div class="title-two title">
                    <h3>工商信息</h3>
                </div>


                <div class="Business">

                    <div class="items">
                        <div class="addTitle">
                            <i class="el-icon-office-building"></i>
                            <span>企业名称</span>
                        </div>
                        <div class="addcontent">
                            <p>{{ info.deptName == null ? '暂无此信息' : info.deptName }}</p>
                        </div>
                    </div>

                    <div class="items">
                        <div class="addTitle">
                            <i class="el-icon-time"></i>
                            <span>成立时间</span>
                        </div>
                        <div class="addcontent">
                            <p>{{ info.businessTime == null ? '暂无此信息' : info.businessTime }}</p>
                        </div>
                    </div>

                    <div class="items">
                        <div class="addTitle">
                            <i class="el-icon-tickets"></i>
                            <span>注册资本</span>
                        </div>
                        <div class="addcontent">
                            <p>暂无此信息</p>
                        </div>
                    </div>

                     <div class="items">
                        <div class="addTitle">
                            <i class="el-icon-user"></i>
                            <span>法人代表</span>
                        </div>
                        <div class="addcontent">
                            <p>{{ info.nickName == null ? '暂无此信息' : info.nickName }}</p>
                        </div>
                    </div>

                     <div class="items">
                        <div class="addTitle">
                            <i class="el-icon-location-outline"></i>
                            <span>注册地址</span>
                        </div>
                        <div class="addcontent">
                            <p>{{ info.address == null ? '暂无此信息' : info.address }}</p>
                        </div>
                    </div>

                    <div class="items">
                        <div class="addTitle">
                            <i class="el-icon-view"></i>
                            <span>统一信用代码</span>
                        </div>
                        <div class="addcontent">
                            <p>{{ info.businessLicense == null ? '暂无此信息' : info.businessLicense }}</p>
                        </div>
                    </div>

                    <div class="items">
                        <div class="addTitle">
                            <i class="el-icon-mobile"></i>
                            <span>所属行业</span>
                        </div>
                        <div class="addcontent">
                            <p>暂无此信息</p>
                        </div>
                    </div>

                    <div class="items">
                        <div class="addTitle">
                            <i class="el-icon-office-building"></i>
                            <span>所在地</span>
                        </div>
                        <div class="addcontent">
                            <p>{{ info.address == null ? '暂无此信息' : info.address }}</p>
                        </div>
                    </div>

                     <div class="items">
                        <div class="addTitle">
                            <i class="el-icon-notebook-1"></i>
                            <span>登陆机关</span>
                        </div>
                        <div class="addcontent">
                            <p>石家庄市场监督管理局</p>
                        </div>
                    </div>
                    
                    <div class="items">
                        <div class="addTitle">
                            <i class="el-icon-date"></i>
                            <span>营业期限</span>
                        </div>
                        <div class="addcontent">
                            <p>{{ info.businessTime == null ? '暂无此信息' : info.businessTime }}</p>
                        </div>
                    </div>

                    <div class="items">
                        <div class="addTitle">
                            <i class="el-icon-pie-chart"></i>
                            <span>企业类型</span>
                        </div>
                        <div class="addcontent">
                            <p>暂无此信息</p>
                        </div>
                    </div>
                    
                    <div class="items">
                        <div class="addTitle">
                            <i class="el-icon-data-line"></i>
                            <span>经营状态</span>
                        </div>
                        <div class="addcontent">
                            <p>{{info.status == 1 ? '未经营' : '经营中'}}</p>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </div>
</template>

<script>
import { apiPrtInfo } from '@/api/prtTime';
export default {
    data(){
        return {
            info:null
        }
    },
    created(){
        this.getBusiness()
    },
    methods:{
        getBusiness(){
            let info = localStorage.getItem('businessInfo');
            if(info == '' || info == null) {
                this.$message({
                    type:'error',
                    message:'错误！！！'
                })
                this.$router.push('/')
                return;
            }
            this.info = JSON.parse(info)
            console.log(this.info)
            for(let i = 0; i < this.info.list.length;i++){
                this.info.list[i].requirements = this.info.list[i].requirements.split(",")
            }
        },
        prtInfo(item){
            if(item != null || item != ''){
                if(item.jobId != null || item.jobId != ''){
                    localStorage.setItem('prtId',item.jobId)
                    apiPrtInfo(item.jobId).then(res => {
                        localStorage.setItem("prtInfo",JSON.stringify(res.data.data))
                        this.$router.push("/merchant")
                    })
                }
            }
            
        }
    }
}
</script>

<style scoped lang = 'less'>
.CompanyBottom{
    width: 1200px;
    margin: 0 auto;
    .content{
        .position{
            text-align: left;
            .title{
                font-size: 17px;
                font-weight: 700;
                margin: 20px 0px;
            }
            .title-one{
                display: flex;
                justify-content: space-between;
                align-items: center;
                a{
                    color: #ff6400;
                }
            }
            .list{
            display: flex;
            justify-content: left;
            flex-wrap: wrap;
            .items{
                background: white;
                padding: 18px;
                width: 323px;
                margin-top: 10px;
                margin-right: 10px;
                .potitle{
                    font-size: 18px;
                    font-weight: 700;
                    display: flex;
                    justify-content: space-between;
                    h4{
                        margin-right: 18px;
                    }
                    span{
                        color: #ff6400;
                    }
                }

                .workTime{
                    margin: 10px 0px;
                    color: #9a9a9a;
                    font-size: 14px;
                    span{
                        margin: 0px 5px;
                    }
                    span:first-child{
                        margin-left:0px ;
                    }
                }

                .Require{
                    span{
                        display: inline-block;
                        padding: 1px 9px;
                        background: #f8f8f8;
                        line-height: 1.1rem;
                        border-radius: 10px;
                        font-size: 12px;
                        color: #999;
                        margin: 0px 4px;
                    }
                    span:first-child{
                        margin-left: 0px;
                    }
                }
                cursor: pointer;
                transition: transform 0.5s;
               
            }
             .item:hover{
                    transform: scale(1.05);
                }
          
            }


        .address{
            padding: 38px 18px;
            background: white;
            display: flex;
            justify-content: space-around;
            >div{
                width: 217px;
                padding-left: 28px;
            }
            .addTitle{
                font-size: 18px;
                color: #999;
                margin-bottom: 14px;
            }
            .addcontent{
                font-weight: 700;
                p{
                    word-wrap: break-word;
                }
            }
        }


        .Business{
            background: white;
            padding: 25px 18px;
            display: flex;
            justify-content: space-around;
            flex-wrap: wrap;
            .items{
                padding-bottom:48px;
                width: 30%;
                margin-left: 12px;
                text-align: center;
                
                .addTitle{
                    font-size: 18px;
                    color: #999;
                    i{
                        margin-right: 6px;
                    }
                }
                .addcontent{
                    font-weight: 700;
                    font-size: 18px;
                    margin-top:18px;
                }
            }

        }

        }
        
    }
}
</style>