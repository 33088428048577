<template>
    <div class="ConTop">
        <!-- 上半部分容器 -->
        <el-container >
            <el-header  class="headee" style="
                                    position: fixed;
                                    left: 0px;
                                    top: 0px;
                                    width: 100%;
                                    z-index: 100">
                <el-header class="el-headerr">
                     <!-- 导航栏组件 -->
                 <!-- BodyNavTop -->
                 <BodyNavTop></BodyNavTop>
                </el-header>
            </el-header>
                
            <el-main class="el-headerr" style="margin-top:80px; width:100%; min-width:1800px; padding:0px; margin:0px auto;">
                 <!-- 轮播图组件 -->  
                 <!-- BodyBanner -->
                 <BodyBanner style="margin-top: 72px;"></BodyBanner>
            </el-main>
        </el-container>
       

       
    </div>
</template>

<script>
// 引入组件
import BodyNavTop from '@/components/BodyNavTop.vue'
import BodyBanner from '@/components/index/xifen/BodyBanner.vue'

export default {
    components:{
        BodyNavTop,
        BodyBanner,
        showDiv:true
    },
    methods:{
        changeShow(e){
            console.log(e);
            console.log(123);
        }
    }
}
</script>

<style lang = 'less' scoped>
   .el-header{
    background: #212329;
   }
</style>