<template>
    <div class="Logintop">
        <div class="login-top">
            <div class="login-tabs-left" :class="{topline:show1}"  @click = 'showRegisterForm'>
                邮箱/账号注册
            </div>
            <div class="login-tabs-righ" :class = "{topline:show2}" @click='showLoginForm'>
                邮箱/账号登录
            </div>
        </div>
    </div>
</template>

<script>
import LoginBus from '@/utils/LoginBus'
export default {
    methods:{
        showRegisterForm(){
            LoginBus.$emit('reg',true)
            this.show1 = true
            this.show2 = false
        },
        showLoginForm(){
            LoginBus.$emit('reg',false)
            this.show1 = false
            this.show2 = true
        }
    },
    data(){
        return{
            show1:false,
            show2:true
        }
    }
}
</script>

<style scoped lang = 'less'>
    .Logintop{
        width: 100%;
        height: 64px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    /* top两个按钮 */
    .login-top{
        justify-content: space-between;
        width: 100%;
        display: flex;
        font-size: 16px;
        color: #878787;
        cursor: pointer;
        padding: 0px 22px;
        box-sizing: border-box;
    }
    .topline{
        transform: scale(1.2);
        color: black;
        font-weight: 400;
        padding-bottom: 1px;
        border-bottom: 2px solid rgb(255, 128, 0);
    }
</style>