<template>
  <div class="BIBottom">
    <!-- 

            要渲染的div

    -->
    <ul class="infinite-list" v-infinite-scroll="load" style="overflow:auto">
      <!-- <li v-for="(item,index) in list" v-bind:key="index" class="infinite-list-item" >

        <div class="BodyList" @click="getMerchant(item.jobId)">

          <div class="left-content">
            <div class="Position-top">
              <span class="Position-name">{{ item.jobTitle }}</span>
              <span class="addrss">[{{ item.jobAddress }}]</span>
              <span class="money">{{ item.salaryRangeId }}</span>
            </div>

            <div class="Position-Require">
              <span class="Require" v-for="(items,indexs) in item.requirementsList" :key="indexs">{{ items }}</span>
            </div>

            <div class="Position-bottom">
              <div class="logo">
                <el-avatar :size="21" :src="item.avatar == '' ? circleUrl : item.avatar"></el-avatar>
                <span>{{ item.deptName }}</span>
              </div>
              <span>{{ item.jobTime }}</span>
              <span>{{ item.type == 'full' ? '全职' : '兼职' }}</span>
            </div>
          </div>
          <div class="right-content">
            <div class="userimg">
              <el-avatar :size="30" :src="item.avatar == '' ? circleUrl : item.avatar"></el-avatar>
              <span class="user-name">{{ item.nickName }}</span>
              <span class="user-Position">HR</span>
            </div>
          </div>
        </div>
      </li>-->
      <li v-for="(item,index) in list" v-bind:key="index" class="infinite-list-item">
        <div class="BodyList" @click="getMerchant(item.jobId)">
          <div class="list-top">
            <h5>{{item.jobTitle}}</h5>
            <span>{{item.jobAddress}}[{{item.salaryRangeId}}]</span>
          </div>
          <div class="bottom-back">
            <div class="list-center">
              <div class="Hravator">
                <el-avatar :size="55" :src="item.avatar ==''?circleUrl:item.avatar"></el-avatar>
                <span>{{item.nickName}}</span>
              </div>
            </div>
            <div class="list-bottom">
              <div class="xuqiu">
                <h5>需求：</h5>
                <div class="list-lists">
                  <span v-for="(items,indexs) in item.requirementsList" :key="indexs">{{items}}</span>
                </div>
              </div>
              <div class="com">
                <div class="company">
                  <el-avatar :size="45" :src="item.avatar ==''?circleUrl:item.avatar"></el-avatar>
                  <span>{{item.deptName}}</span>
                </div>
                <div class="list-lists">
                  <span>{{item.jobTime}}</span>
                  <span>{{item.type == 'full'?'全职':'兼职'}}</span>
                  <span v-for="(items,indexs) in item.requirementsList" :key="indexs">{{items}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>
      <!-- <li v-for="(item,index) in list" v-bind:key="index" class="infinite-list-item" >
        <div class="BodyList" @click="getMerchant(item.jobId)">
          <div class="left-content">
            <div class="Position-top">

              <span class="Position-name">{{ item.jobTitle }}</span>
              <span class="addrss">[{{ item.jobAddress }}]</span>
              <span class="money">{{ item.salaryRangeId }}</span>
            </div>

            <div class="Position-Require">
              <span class="Require" v-for="(items,indexs) in item.requirementsList" :key="indexs">{{ items }}</span>
            </div>

            <div class="Position-bottom">
              <div class="logo">
                <el-avatar :size="21" :src="item.avatar == '' ? circleUrl : item.avatar"></el-avatar>
                <span>{{ item.deptName }}</span>
              </div>
              <span>{{ item.jobTime }}</span>
              <span>{{ item.type == 'full' ? '全职' : '兼职' }}</span>
            </div>
          </div>
          <div class="right-content">
            <div class="userimg">
              <el-avatar :size="30" :src="item.avatar == '' ? circleUrl : item.avatar"></el-avatar>
              <span class="user-name">{{ item.nickName }}</span>
              <span class="user-Position">HR</span>
            </div>
          </div>
        </div>
      </li>-->
    </ul>
    <pagination
      v-show="total>0"
      :total="total"
      :page.sync="queryParams.pageNum"
      :limit.sync="queryParams.pageSize"
      @pagination="load"
    />
  </div>
</template>

<script>
import { apiList } from "@/api/prtTime";
import { apiPrtInfo } from "@/api/prtTime";
export default {
  data() {
    return {
      // 总条数
      total: 0,
      count: 0,
      circleUrl:
        "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
      queryParams: {
        pageNum: 1,
        pageSize: 20,
        type: null,
        salaryRangeId: null,
        jobTitle: null,
        jobDescription: null,
        requirements: null,
        jobTime: null,
        jobAddress: null,
        publishTime: null,
        deadlineTime: null,
        salaryNumber: null,
        status: null
      },
      list: []
    };
  },
  computed: {
    //获取vuex中的值,这里是获取index
    paramListIndex() {
      return this.$store.state.paramList.index;
    },
    //获取vuex中的值,这里是获取titleSearch
    paramListTitleSearch() {
      return this.$store.state.paramList.titleSearch;
    }
  },
  methods: {
    async getMerchant(prtId) {
      console.log(prtId);
      await apiPrtInfo(prtId).then(res => {
        //将查询到的数据存放到localStore中
        localStorage.setItem("prtInfo", JSON.stringify(res.data.data));
        //存放档期啊prtid
        localStorage.setItem("prtId", prtId);
      });
      this.$router.push("/merchant");
    },
    async load() {
      //定义item修改row
      let items = [];
      //获取招聘信息数据
      await apiList(this.queryParams).then(res => {
        this.total = res.data.total;
        items = res.data.rows;
        this.list = items;
      });
    }
  },
  watch: {
    //监听index值
    paramListIndex: {
      handler(val) {
        this.queryParams.type = val === "home" ? null : val;
        this.load();
      }
    },
    paramListTitleSearch: {
      handler(val) {
        this.queryParams.jobTitle = val;
        this.load();
      }
    }
  }
};
</script>

<style scoped lang = 'less'>
/* .BodyList {
  margin: 20px 0px;
  width: 100%;
  border-radius: 20px;
  cursor: pointer;
  background: white;
  box-shadow: 0px 2px 8px 1px #d9d9d9;
  display: flex;
  justify-content: space-between;
  padding: 18px 30px;
  box-sizing: border-box;
  align-items: center;
  transition: transform 0.2s;
  .left-content {
    width: 70%;
    .Position-top {
      text-align: left;
      span {
        font-size: 18px;
        margin: 0px 6px;
        font-weight: 700;
      }
      .money {
        color: #ff6a00;
        margin: 0px 10px;
        font-size: 20px;
      }
    }
    .Position-Require {
      display: flex;
      justify-content: left;
      margin: 18px 0px;
      span {
        display: inline-block;
        font-size: 12px;
        color: black;
        margin: 6px 5px;
        background: #eaeaea;
        border-radius: 10px;
        padding: 0px 7px;
        line-height: 1.3rem;
      }
    }
    .Position-bottom {
      display: flex;
      justify-content: left;
      .logo {
        display: flex;
        justify-content: left;
      }
      span {
        display: inline-block;
        font-size: 12px;
        color: black;
        margin: 0px 6px;
        background: #eaeaea;
        border-radius: 10px;
        padding: 0px 7px;
        line-height: 1.3rem;
      }
      .el-avatar {
        padding: 0;
      }
    }
  }
  .right-content {
    font-size: 14px;
    .userimg {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .user-name {
      margin: 8px 0px;
    }
  }
} */

.infinite-list {
  /* overflow: auto; */
  overflow: visible !important;
  display: flex;
  align-items: center;
  justify-content: left;
  flex-wrap: wrap;
  .infinite-list-item {
    /* box-shadow: 0px 0px 0px 0px #ffe8d4 ; */
    transition: all 0.3s linear;
    cursor: pointer;
    background: #ffffff;
    width: 242px;
    margin-right: 9px;
    /* background: white; */
    border-radius: 40px 40px 0px 0px;
    min-height: 350px;
    margin-bottom: 20px;
    position: relative;
    top: 0px;
    .list-top {
      background: #00dcff;
      border-radius: 40px 40px 0px 0px;
      padding: 10px 20px;
      box-shadow: 0px 6px 16px 0px #8cefff;
      h5 {
        font-size: 16px;
        margin-bottom: 4px;
        color: white;
        width: 180px;
        text-align: center;
        margin: 0 auto;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      span {
        color: #f8f8f8;
        font-size: 10px;
        max-width: 190px;
    /* width: 190px; */
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: normal;
    display: inline-block;
      }
    }
    .bottom-back {
      padding: 6px  0px;
    }

    .list-center {
      .Hravator {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        /* margin: 20px 0px; */
        span:last-child {
          font-size: 12px;
          display: inline-block;
          margin-top: 10px;
        }
      }
    }

    .list-bottom {
      padding: 1px 15px;
      h5 {
        text-align: left;
        font-size: 17px;
      }
      .list-lists {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        span {
          padding: 1px 11px;
          background: #f1f1f1;
          display: inline-block;
          margin-right: 8px;
          border-radius: 7px;
          margin-top: 9px;
          color: #3c3c3c;
          /* color: #9e9e9e; */
          font-size: 12px;
        }
      }
      .com {
        margin: 3px 0px;
          .company {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          .el-avatar--circle {
            margin-right: 10px;
          }
        }
      }
    }
  }
  .infinite-list-item:hover {
    top: -10px;
    box-shadow: 0px 9px 22px 0px #dcdcdc;
  }
}

.infinite-list {
  padding: 0px 10px;
}
</style>