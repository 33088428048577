import { render, staticRenderFns } from "./BusinEss.vue?vue&type=template&id=679caca3&scoped=true"
import script from "./BusinEss.vue?vue&type=script&lang=js"
export * from "./BusinEss.vue?vue&type=script&lang=js"
import style0 from "./BusinEss.vue?vue&type=style&index=0&id=679caca3&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "679caca3",
  null
  
)

export default component.exports