import Vue from 'vue'
import App from './App.vue'
import store from './store/store'; // 引入你的Vuex store  
import '@/utils/request'; // 引入拦截器文件，确保它在应用启动时运行  
import Cookies from "js-cookie";
import '../publicCss/publicCss.css'




import router from "@/router/index"
import Pagination from "@/components/Pagination";
import { BASE_URL } from './utils/basr_url';
import { checkLogin } from './utils/authPathChack';

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import WebSocket from './utils/WebSocket';

Vue.use(ElementUI)


//全局方法挂载
Vue.prototype.$BASE_URL = BASE_URL
Vue.prototype.$CheckLoginStatic = checkLogin
Vue.prototype.$websocket  = WebSocket

//全局组件挂载
Vue.component('Pagination', Pagination)


Vue.config.productionTip = false

new Vue({
  store, // 将store添加到Vue实例中 
  render: h => h(App),
  router
}).$mount('#app')
