import request from '@/utils/request'

export function apiList(prtTimeJobs){
    return request({
        url:'/api/recruiters/prtList',
        method:'GET',
        headers:{
            isToken:false
        },
        params:prtTimeJobs
    })
}

export function apiPrtInfo(prtId){
    return request({
        url:'/api/recruiters/prtInfo',
        method:'POST',
        headers:{
            isToken:false
        },
        params:{prtId}
    })
}