<template>
    <div class="ssss">
        <el-container>
            <el-header class="el-headee">
                <BodyNavTop class="el-headerr"> </BodyNavTop>
            </el-header>
            <el-main> 
                <div class="show">
                    <h3>校园招聘用户隐私协议</h3>
                    <ul>
                        <li>1,dsadasdsadasdads</li>
                        <li>1,dsadasdsadasdads</li>
                        <li>1,dsadasdsadasdads</li>
                        <li>1,dsadasdsadasdads</li>
                        <li>1,dsadasdsadasdads</li>
                        <li>1,dsadasdsadasdads</li>
                        <li>1,dsadasdsadasdads</li>
                        <li>1,dsadasdsadasdads</li>
                        <li>1,dsadasdsadasdads</li>
                        <li>1,dsadasdsadasdads</li>
                        <li>1,dsadasdsadasdads</li>
                        <li>1,dsadasdsadasdads</li>
                        <li>1,dsadasdsadasdads</li>
                    </ul>
                </div>
            </el-main>
        </el-container>
    </div>
</template>

<script>
import BodyNavTop from "@/components/BodyNavTop.vue";

import '../../../publicCss/publicCss.css'
export default {
    components:{
        BodyNavTop
    }
}
</script>

<style lang='less' scoped>
    .show{
        width: 800px;
        margin: 0 auto;
        background: white;
        padding: 10px 40px;
        h3{
            font-size: 30px;
        }
        ul li{
            text-align: left;
        }
    }
</style>