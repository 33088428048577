<template>
    <div class="ConBtm">
        <!-- 下半部分容器 -->
        <el-container style="position:relative;">

            <!-- body左边组件 -->
            <!-- BodyLeft -->
             <el-main style="  min-height: 800px;     width: 1278px;">
                <BodyLeft></BodyLeft>
             </el-main>

             <!-- body右边组件 -->
              <!-- BodyRight -->
          <el-aside class="el_aside" style="  width: 28%;
                                                position: absolute;
                                                height: 689px;
                                                right: -119px;
                                                top: -105px;
                                                z-index: 10;
                                                ">
            <LoginElement v-if="!isLogin" style="position: absolute;
                                                right: 0px;
                                                top: 117px;"></LoginElement>
            <BodyRight style="   position: absolute;
                                right: 0px;
                                top: 117px;" v-else></BodyRight>
            </el-aside>
        </el-container>
        
        
    </div>
</template>

<script>
import { getToken } from '@/utils/auth'
//   引入
import BodyLeft from '@/components/index/xifen/BodyLeft.vue'
//    显示个人信息
import BodyRight from '@/components/index/xifen/BodyRight.vue'
//     显示登录注册
import LoginElement from '@/views/LoginElement/LoginElement.vue'

export default {
    data(){
        return{
            isLogin:getToken() != null ? true : false
        }
    },
    components:{
        BodyLeft,
        BodyRight,
        LoginElement
    }
}
</script>

<style scoped>
    .el_aside>div{
        margin: 0 auto;
    }
</style>