<template>
   <el-header>
     <div class="NavTop">
        <el-row>
            <el-col :span="2">
                <div class="grid-content bg-purple">
                    <div class="logo" >
                        <img src="../assets/logo.png" @click = 'getIndex("home")' alt="">
                    </div>
                </div>
            </el-col>

            <el-col :span="6">
                <div class="grid-content bg-purple-light">
                        <el-menu
                        :default-active="touteIndex"   
                        class="el-menu-demo"
                        mode="horizontal"
                        text-color = 'black'
                        @select="handleSelect"
                        active-text-color="orange"
                        background-color = '#212329'
                                        >
                
                            <el-menu-item index="1" @click = 'getIndex("home")'>首页</el-menu-item>
                            
                            <el-menu-item index="2" @click = 'getIndex("part")'>
                                兼职
                            </el-menu-item>
                            <el-menu-item index="3" @click = 'getIndex("full")'>
                                全职
                            </el-menu-item>
                        </el-menu>
                </div>
            </el-col>

            <el-col :span="10">
                <div class="grid-content bg-purple">
                    <div class="NavInput">
                        <el-autocomplete
                        v-model="statee"
                        :fetch-suggestions="querySearchAsync"
                        placeholder="请输入内容"
                        @select="handleINdex"
                        
                        ></el-autocomplete>
                        <el-button type="primary" icon="el-icon-search" @click="searchTitle()">搜索</el-button>
                    </div>
                </div>
            </el-col>

            <el-col :span="6" class="busin">
                <div class="business">
                    <span><router-link to="/busin">我是招聘方</router-link></span>
                </div>
                <div class="grid-content bg-purple-light">
                    <ul class="user">
                        <li>
                            <div class="userdiv" @click = 'getshow' v-if="isToken">
                                <span v-if="isToken">你好：</span>
                                <span class="username">{{ userFrom.nickName }}</span>   <!-- 这一块部分是渲染  -->
                                <i class="el-icon-arrow-down el-icon--right"></i>       
                                <el-avatar :size="40" :src="userFrom.avatar === null ? userFrom.circleUrl : userFrom.avatar"></el-avatar> 
                                <div class="user-select" v-show="showDiv" >
                                    <ul>
                                        <li @click="to('user')"><router-link to="#">个人信息</router-link></li>
                                        <li @click="to('deli')"><router-link to="#">我的投递</router-link></li>
                                        <!-- <li @click="to('deli')"><router-link to="#">我的收藏</router-link></li> -->
                                        <li @click="to('pri')"><router-link to="#">账号设置</router-link></li>
                                        <li @click="to('chat')"><router-link to="#">我的聊天</router-link></li>
                                        <li @click="logOut()"><router-link to="#" >退出登录</router-link></li>
                                    </ul>
                                </div>
                            </div>
                        </li>
                    </ul>
                 </div>
            </el-col>
        </el-row>
    </div>
   </el-header>
</template>

<script>

// import { mapGetters, mapActions } from 'vuex';  
import { removeToken,getToken } from '@/utils/auth'
import { logout } from '@/api/login'
export default {

    
    data(){
        return{

            navInput:'11',
            
            // 搜索框
            restaurants: [],
            statee: '',
            timeout:  null,
            // user-select   
            showDiv:false,
            // 参数
            touteIndex : '1',
            isToken: getToken() != null ? true : false,
            userFrom:{
                nickName:'',
                avatar:'',
                circleUrl: "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
            }
        }
    },
    created(){
        //获取登陆时接受的名字和头像
        this.userFrom.nickName = localStorage.getItem('nickName')
        this.userFrom.avatar = localStorage.getItem('avatar')
    },
     methods: {
        //进入页面之前校验是否需要登陆
        to(item){
            console.log(item)
            this.$CheckLoginStatic(item)
        },
        //设置搜索内容
        searchTitle(){
            //设置搜索，使用store来进行组件传参
            this.$store.commit("updateParamListTitleSearch",this.statee)
        },
        // ...mapActions(['login', 'logout']) // 映射actions到组件的方法
        getIndex(index) {  
            const currrouter = this.$router.currentRoute
            currrouter.path !== '/'? this.$router.push("/") : '';
            //设置store中的值
            this.$store.commit('updateParamListIndex',index)
        },
        getUser(){
            this.$router.push('/user')
        },
        getshow(){
            //是否展示上述
            if(true){
                this.showDiv = !this.showDiv
            }else{
                alert("请先登录")
            }
        },

      loadAll() {
        return [

            //   下面是需要动态渲染的数据,以后要删 
            //   下面是需要动态渲染的数据,以后要删 
            //   下面是需要动态渲染的数据,以后要删 
            //   下面是需要动态渲染的数据,以后要删 
            //   下面是需要动态渲染的数据,以后要删 


        //   { "value": "三全鲜食（北新泾店）", "address": "长宁区新渔路144号" },
       
        ];
      },


      //  获取   替换
      querySearchAsync(queryString, cb) {
        var restaurants = this.restaurants;
        var results = queryString ? restaurants.filter(this.createStateFilter(queryString)) : restaurants;

        //  加载时间   //  后期更换

        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          cb(results);
        }, 1 * Math.random());
      },
      createStateFilter(queryString) {
        return (statee) => {
          return (statee.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
        };
      },

     // 导航栏菜单
     handleINdex(key, keyPath) {
        console.log(key, keyPath);
      },
      // input输入框   
      handleSelect(item) {
        // console.log(item);
      },
      logOut(){
        logout().then(res => {
            this.$message({
                type:'success',
                message:'退出成功'
            })
            //删除token缓存
            removeToken()
            //删除存储的名称和头像
            localStorage.removeItem('nickName')
            localStorage.removeItem('avatar')
            this.$router.push("/");
            setTimeout(() => {
                location.reload()
            },1000) 
        })
        
      }
    },
    mounted() {
        this.restaurants = this.loadAll();
        //   获取页面的路由
        const routerIndex = this.$route.query.index
        this.touteIndex = routerIndex || '1'
    }
}
</script>
<style scoped lang = 'less'>

.busin{
    display: flex;
    justify-content: center;
    align-items: center;
   .business{
    span{
        margin-right: 12px;
        cursor: pointer;
        transition: all 0.2s;
        a{
            color:white ;
        }
    }
    span:hover{
        padding-bottom:8px ;
        border-bottom: 3px solid orangered;
        box-sizing: border-box;
    }
   }
}

.el-row{
    padding-top: 0 !important;
    li{
    color: white !important;
    }
}


 .el-header{
    background: #212329;
   }
    .NavTop{
            width: 100%;
    }
    .el-row{
        padding-top: 10px;
        display: flex;
        align-items: center;
        color: white;
        .user{
            display: flex;
            justify-content: right;
        }
        .user li{
            display: inline-block;
        }
        .userdiv{
            display: flex;
            align-items:center;
            justify-content: right;
            position: relative;
            .username{
                margin: 0px 2px;
                transition: all 0.2s;
            }
            .el-avatar{
                margin-left: 8px;
            }
            *{
                cursor: pointer;
            } .user-select{
                  background: white;
                border-radius: 10px;
                box-shadow: 0px 2px 3px 1px #dbdbdb;
                position: absolute;
                padding: 8px 0px;
                top: 48px;
                right: 26px;
                z-index: 8;
                li{
                    transition: all 0.2s;
                    width: 100%;
                    a{
                        color: black;
                        transition: all 0.2s;
                        cursor: pointer;
                        display: inline-block;
                         padding: 5px;
                        padding: 10px 14px;
                            width: 116px;
                            font-weight: 400;
                    }
                    a:hover{
                        background: #efefef;
                        color: rgb(255, 115, 0);
                    }
                }
         }
        }
         .userdiv:hover .username{
                color: rgb(255, 115, 0);
            }
    }

    .logo img{
        width: 40px;
    }
    .navlist{
        display: flex;
        justify-content: left;
        li{
               padding: 0px 13px 0px 13px;
                margin: 0px 10px;
                cursor: pointer;
                transition: all 0.2s;
                height: 30px;
                display: flex;
                align-items: center;
                padding-bottom: 3px;
        }
        .li-active{
            border-bottom: 2px solid rgb(255, 115, 0);
        }
    }

    /* 
        input
    */
    .NavInput{
        position: relative;
            display: flex;
    justify-content: center;
    align-items: center;
        .el-autocomplete{
            width: 400px;
        }
        .el-button{
            height: 44px;
            border: 0;
            color: white;
            font-size: 14px;
            transition: transform 0.2s;
            background: #ff6400;
            padding: 12px 20px;
            border-radius: 0px;
            font-weight: 600;
            transform: scale(0.9);
        }
    }
</style>