import request from "@/utils/request"

export function getUserJonInfo(){
    return request({
        url:"/api/jobhunting/getUserInfo",
        method:"post",
        headers:{
            isToken:true
        }
    })
}


// 个人信息获取
export function getUserInfoApi(){
    return request({
        url:'/api/jobhunting/getUserInfo',
        method:'POST',
        headers:{
            isToken:true
        }
    })
}
// 个人信息编辑
export function updateUsetInfoApi(userInfo){
    return request({
        url:'/api/jobhunting/updateUserInfo',
        method:'POST',
        data:userInfo,
        headers:{
            isToken:true
        }
    })
}

// 优势亮点获取
export function getUserAdvantageApi(){
    return request({
        url:'/api/jobhunting/getSpot',
        method:"POST",
        headers:{
            isToken:true
        }
    })
}

// 优势亮点添加
export function updateUserAdvantageApi(userAdvant){
    return request({
        url:`/api/jobhunting/sendSpot?spot=${userAdvant}`,
        method:'POST',
        headers:{
            isToken:true
        }

    })
}

// 获取荣誉证书
export function getUserhonor(){
    return request({
        url:'/api/jobhunting/getCertificate',
        method:'GET',
        headers:{
            isToken:true
        }
    })
}
// 添加荣誉证书
export function updataUserhonor(arr){
    return request({
        url:'/api/jobhunting/sendCertificate',
        method:'POST',
        data:arr,
        headers:{
            isToken:true
        }
    })
}
// 获取附件信息
export function getUserappendix(){
    return request({
        url:'/api/jobhunting/getUserText',
        method:'GET',
        headers:{
            isToken:true
        }
    })
}
// 添加附件信息
export function updataUserappendix(arr){
    return request({
        url:`/api/jobhunting/sendUserText`,
        method:'POST',
        data:arr,
        headers:{
            isToken:true
        }
    })
}
