<template>
  <div class="CompanyInfrom">
    <el-container>
     <el-header class="el-headee">
         <!--   顶部信息  CompanyTop  -->
         <CompanyTop class="el-headerr"></CompanyTop>
     </el-header>
     
        <!-- 背景图部分 -->
       <div class="back">
          <ComHeader class="el-headerr"></ComHeader>
       </div>
             
     <el-main>
         <!-- 中间的介绍 -->
         <CompanyCenter ></CompanyCenter>
     </el-main>
     <el-footer>
         <!--   下面的细节信息 CompanyBottom -->
         <CompanyBottom></CompanyBottom>
     </el-footer>
    </el-container>
  </div>
</template>

<script>
import CompanyTop from "@/components/company/CompanyTop.vue";
import CompanyCenter from "@/components/company/CompanyCenter.vue";
import CompanyBottom from "@/components/company/CompanyBottom.vue";
import ComHeader from '@/components/company/ComHeader.vue'

import '../../../publicCss/publicCss.css'
export default {
  components: {
    CompanyTop,
    CompanyCenter,
    CompanyBottom,
    ComHeader
  }
};
</script>

<style scoped lang = 'less'>
.el-header{
    height: auto !important;
}
.back{
  background: #e5e5e5;
  width: 100%;
}
</style>