<template>
  <div class="BodyRight">


    <div class="user-information">

      <div class="inform-top"  @click="getInform" style=" cursor: pointer;">
        <el-avatar :size="58" :src="avatar?avatar:squareUrl"></el-avatar>
        <div class="inform">
          <span class="user-name">{{nickName}}</span>
          <!-- <span class="mine">我是学生</span> -->
        </div>
        <div class="bianji" @click="getInform">
          <span>编辑简历 &nbsp;&nbsp;></span>
        </div>
      </div>

      <div class="inform-content">
        <div class="backtop">
          <div class="inform-item" @click="getdeli">
            <span class="dd">11</span>
            <span class="text">我的投递</span>
          </div>
          <div class="inform-item">
            <span class="dd">99</span>
            <span class="text">我的收藏</span>
          </div>
        </div>

        <div class="backbottom">
          <div class="inform-item" @click="getWord()">
            <el-avatar shape="square" :size="50" :src="squareUrl"></el-avatar>
            <span class="text">导出简历</span>
          </div>
          <div class="inform-item">
            <el-avatar shape="square" :size="50" :src="squareUrl"></el-avatar>
            <span class="text">刷新简历</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      squareUrl:"https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
      nickName: '',  
      avatar: '', 

    };
  },
  methods:{
    getInform(){
      this.$router.push('/user')
    },
    getdeli(){
      this.$router.push('/deli')
    },
    getWord(){
      this.$router.push('/word')
    }
  },
  created(){
       this.nickName = localStorage.getItem('nickName'); // 如果没有找到nickName，则设置为空字符串  
       this.avatar = localStorage.getItem('avatar') ; // 如果没有找到avatar，则设置为空字符串  
  } 
};
</script>

<style scoped lang='less'>

    .user-information{
        background: white;
        width: 280px;
        padding: 14px;
        margin: 0 auto;
        box-shadow:0px 2px 8px 1px #d9d9d9;
        border-radius: 13px;
        overflow: hidden;
        z-index: 10;
        .inform-top{
            display: flex;
            justify-content: left;
            align-items: center;
            text-align: left; 
            .inform{
                margin-left:10px ;
            }
            .inform span{ 
                display: block;
            }
            .user-name{
                font-size: 18px;
                font-weight: 700;
                margin-bottom: 4px;
                max-width: 125px;
                /* width: 130px; */
                display: inline-block;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }
            .mine{
                font-size: 12px;
            }
            position: relative;
            .bianji{
                width: 100px;
                height: 1.9rem;
                color: white;
                background: orange;
                position: absolute;
                right: -21px;
                top: 13px;
                text-align: center;
                border-radius: 10px;
                line-height: 1.9rem;
                display: block;
                cursor: pointer;
            }
        }

            .inform-item{
                    padding: 10px;
                    cursor: pointer;
                }
        //   center
        .inform-content{
            .backtop{
                display: flex;
                justify-content: space-around;
                border-bottom: 1px solid #dadada;
                padding: 20px 0px;
                span{
                    display: block;
                    margin: 0 auto;
                }
                .dd{
                    font-size: 1.6rem;
                }
                .text{
                    font-size: 0.7rem;
                    margin-top: 10px;
                }
            }
        }

        // bottom
        .backbottom{
            display: flex;
            justify-content: space-around;
            margin-top: 16px;
            span{
                display: block;
                margin: 10px auto;
            }
            
        }
    }
    

</style>