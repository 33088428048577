import VueRouter  from "vue-router";
import Vue from "vue";
import { getToken } from "@/utils/auth";
import Home from "@/views/IndexElement/IndexElement"//   首页
import Merchant from '@/views/Merchant/MerElement' // 商家信息页
import CompanyInfrom from '@/views/CompanyInfrom/CompanyInfrom'  /// 公司信息页
import UserInfrom from '@/views/UserInform/UserInform' /// 个人信息页
import BusinEss from '@/views/BusinEss/BusinEss.vue' // 商家注册页
import UserChat from '@/views/UserChat/UserChat'//  用户交流页面
import DeLiver from '@/views/delivery/DeLivery'//  用户投递收藏页面
import PriVacy from '@/views/PriVacy/PriVacy'//  用户隐私页面
import UserShow from '@/views/show/UserShow'//  协议页面
import UserSh from '@/views/show2/UserSh'//  隐私页面
import Word from '@/views/getWord/getWord' // 导出简历页面

const routers = [
    // {
    //   name:"登录页",
    //   path:"/"  ,//这要写 / 这是主路径
    //   component:Index ,
    //   children:[]
    // },
    {
        name:"首页",
        path:"/",
        component:Home,
        children:[]
    },
    {
        name:"商家信息",
        path:"/merchant",//merchant
        component:Merchant,
        children:[]
    },
    {
        name:'公司信息',
        path:'/company',
        component:CompanyInfrom,
        children:[]
    },
    {
        name:'个人信息',
        path:'/user',
        component:UserInfrom,
        children:[]
    },
    {
        name:'商家注册',
        path:'/busin',
        component:BusinEss,
        children:[]
    },
    {
        name:'用户交路',
        path:'/chat',
        component:UserChat,
        children:[]
    },
    {
        name:'投递收藏',
        path:'/deli',
        component:DeLiver,
        children:[]
    },
    {
        name:'账号设置',
        path:'/pri',
        component:PriVacy,
        children:[]
    },
    {
        name:'协议',
        path:'/ush',
        component:UserShow,
        children:[]
    },
    {
        name:'隐私',
        path:'/us',
        component:UserSh,
        children:[]
    },
    {
        name:'导出',
        path:'/word',
        component:Word,
        children:[]
    },
]

Vue.use(VueRouter)
let router = new VueRouter({
    mode:"hash",
    routes:routers
})
const pathArr = ['/chat','/user','/deli','/pri']
router.beforeEach((to,from,next) => {
    console.log(to.path,'path')
    if(pathArr.find(item => item === to.path)){
        if(getToken()){
            next()
        }else{
            next('/')
        }
    }else{
        next()
    }
    
})
export default router