<template>
  <form action>
    <div class="form-group">
      <el-input v-model="form.email" placeholder="请填写邮箱"></el-input>
    </div>
    <div class="form-group">
      <el-input v-model="form.phonenumber" placeholder="请填写手机号"></el-input>
    </div>
     <div class="form-group">
      <el-input v-model="form.nickName" placeholder="请填写您的名称"></el-input>
    </div>
      <div class="form-group">
      <el-input v-model="form.userName" placeholder="请填写您的账号"></el-input>
    </div>
    <div class="form-group">
      <el-input placeholder="请输入密码" v-model="form.password" show-password></el-input>
    </div>
    <div class="form-group">
      <el-input placeholder="确认密码" v-model="checkPass" show-password></el-input>
    </div>
    <div class="form-group">
      <el-input v-model="form.code" placeholder="验证码"></el-input>
      <div class="Pass">
        <a
          href="javascript:;"
          @click="startCountdown"
          :class="{ disabled: isCounting }"
        >{{ isCounting ? countdownText+'之后可以重新获取' : '获取验证码' }}</a>
      </div>
    </div>
    <div class="bottom-btn">
      <button :disabled="isconfim" @click="Register()" type="submit" class="btn btn-primary">注册</button>
    </div>
  </form>
</template>

<script>
import { register,sendEmail } from '@/api/regiester'
export default {
  props:['re'],
  data() {
    return {
      //  默认按钮不能点击
      isconfim: false,

      checkPass: "", //确认密码
      form: {
        password: "", //用户的密码
        code: "", //验证码
        email: "", //用户邮箱
        userName: "", //用户账号
        phonenumber: "" ,// 用户的手机号
        nickName:''//用户的名称
      },
      formHash: "",
      countdown: 60, // 倒计时总时间
      countdownText: "", // 倒计时文本
      isCounting: false, // 是否正在倒计时
      countdownTimer: null // 倒计时计时器
    };
  },
  computed: {
    computedFormHash() {
      return JSON.stringify(this.form);
    }
  },
  watch: {
    computedFormHash(newVal, oldVal) {
      if (newVal !== oldVal) {
        // console.log("Form changed:", this.form);
        this.formHash = newVal;
      }
    }
  },
  methods: {
    Register() {
      //再检查一遍
      let isChack = this.chackDataIsSuccess()
      if(!isChack) return
      if(!this.re){
        this.$message({
          type:'error',
          message:"请确认协议"
        })
        return;
      }
      register(this.form).then(res => {
        console.log(res)
        if(res.data.code === 200){
          this.$message({
            type:'success',
            message:res.data.msg
          })
        }else{
          this.$message({
            type:'error',
            message:res.data.msg
          })
        }
      })


    },

    startCountdown() {
      //先检查一遍
      let isChack = this.chackDataIsSuccess()
      if(!isChack) return
    // 获取验证码
      const form = new FormData()
      form.append('email',this.form.email)
      sendEmail(form).then(res => {
        if(res.data.code === 200){
          this.$message({
            type:'success',
            message:res.data.msg
          })
        }else{
          this.$message({
            type:'error',
            message:res.data.msg
          })
        }
        
      })

    },
    endCountdown() {
      clearInterval(this.countdownTimer);
      this.countdownTimer = null;
      this.countdown = 60;
      this.countdownText = "";
      this.isCounting = false;
    },
    //检查数据是否合格
    chackDataIsSuccess(){
      // 验证邮箱格式
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(this.form.email)) {
        alert("请输入正确的邮箱格式！");
        return false;
      }

      // 验证手机号格式（以中国大陆手机号为例）
      const phoneRegex = /^1[3-9]\d{9}$/;
      if (!phoneRegex.test(this.form.phonenumber)) {
        alert("请输入正确的手机号格式！");
        return false;
      }

        // 验证账号 
       const Regex = /^[A-Za-z0-9]{1,18}$/;  
        if (!Regex.test(this.form.userName)) {  
            alert('请输入正确的账号，只能包含英文字母，且长度不能超过18个字符！');  
            return false;  
        }
      // 验证名称
      // const nameRegex = /^[a-zA-Z\u4e00-\u9fa5]{1,18}$/;  
      // if (!nameRegex.test(this.form.nickName)) {  
      //     alert('请输入正确的名称，只能包含英文字母和中文字符，且长度不能超过18个字符！');  
      //     return false;  
      // }
      // 验证密码和确认密码是否一致，且不为空
      console.log(this.checkPass,this.form.password);
      if (
        !this.form.password ||
        !this.checkPass ||
        this.form.password !== this.checkPass
      ) {
        alert("请输入密码，并确保两次输入的密码一致！");
        return false;
      }

      if (!this.isCounting) {
        this.isCounting = true;
        this.countdownText = this.countdown;
        this.countdownTimer = setInterval(() => {
          if (this.countdown > 0) {
            this.countdown--;
            this.countdownText = this.countdown;
          } else {
            this.endCountdown();
          }
        }, 1000);
      }
      return true;
    }
  }
};
</script>

<style  lang = 'less' scoped>
.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.form-group {
  position: relative;
  margin: 10px 0px;

  .Pass {
    position: absolute;
    right: 0px;
    top: 50%;
    transform: translate(-8%, -50%);
    background: white;
    padding-right: 20px;
    padding-right: 5px;
    a {
      color: #8b8b8b;
      transition: all 0.2s;
    }
    a:hover {
      color: #ff6400;
    }
  }
  .Pass::before {
    content: "|";
    color: #8b8b8b;
    margin-right: 10px;
  }
}

.form-group input {
  border-radius: 20px;
  padding-left: 12px;
  height: 50px;
  margin: 20px 0px;
}
.bottom-btn {
  text-align: center;
  margin-top: 20px;
  .btn {

    display: flex;
        justify-content: center;
        width: 100%;
    border-radius: 18px;
    background: #ff6400;
    color: white;
    cursor: pointer;
    border: 0px;
    padding: 8px 4px;
  }
}
</style>