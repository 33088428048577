<template>
        <form action="">
            <div class="form-group">
                <el-input v-model="form.username" placeholder="账号/邮箱"></el-input>
            </div>
            <div class="form-group grop-pass">
                <el-input placeholder="请输入密码" v-model="form.password" show-password></el-input>
                <!-- <div class="Pass">
                    <a href="javascript:;">找回密码</a>
                </div> -->
            </div>
            <div class="bottom-btn">
                <button @click = 'Login()' type="submit" class="btn">登录</button>
            </div>
            <div class="email_pass">
                
            </div>
            </form>
</template>

<script>
import { loginForm,getNickName } from '@/api/login'
import { getToken, setToken } from '@/utils/auth'
export default {
    props:['re'],
    data(){
        return{
            form: {  
                username:'',//登录账号
                password:'',//登录密码
                email:false,//是否是邮箱登录
            },  
             formHash: ''  
        }
    },
    methods:{
        Login(){
            
            // this.$router.push('/Index')
             if(!this.re){
                this.$message({
                    type:'error',
                    message:"请确认协议"
                })
                return;
            }
            let isLogin = false;
            //判断是不是邮箱登录
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (emailRegex.test(this.form.username)) {
                this.form.email = true;
            }
            loginForm(this.form).then(res => {
                if(res.data.code === 200){
                    this.$message({
                        type:"success",
                        message:'登陆成功'
                    })
                    
                    //将token存放到cookie中
                    setToken(res.data.token)
                    //查询用户信息，获取用户姓名
                    getNickName().then(res => {
                        console.log(res)
                        if(res.data.code === 403){
                            this.$message({
                                type:'error',
                                message:'您没有此权限，请联系管理员'
                            })
                            localStorage.setItem('nickName','游客')
                            localStorage.setItem('avatar',null)
                        }else{
                            localStorage.setItem('nickName',res.data.msg)
                            localStorage.setItem('avatar',res.data.avatar)
                            localStorage.setItem('pc_user_name',res.data.pc_user_name)
                        }
                       
                    }).catch(res => {
                        this.$message({
                            type:'error',
                            message:'服务器异常，请稍后重试'
                        })
                    })
                    
                }else{
                    this.$message({
                        type:"error",
                        message:res.data.msg
                    })
                }
                //刷新页面
                setTimeout(() => {
                    location.reload()
                },500)
            })
        },
    }
}
</script>

<style  lang='less' scoped>

    
    .form-group{
        position: relative;
        margin: 10px 0px;
        .form-group{
            position: relative;
        }
    
    .Pass{
            position: absolute;
            right: 0px;
            top: 50%;
            transform: translate(-8%, -50%);
            background: white;
            padding-right: 20px;
          padding-right: 5px;
        a{
            color: #8b8b8b;
            transition: all 0.2s;
        }
        a:hover{
            color: #ff6400;
        }
     }
     .Pass::before{
        content: '|';
        color: #8b8b8b;
        margin-right: 10px;
     }
   }
   
   .form-group .el-input{
    border-radius: 20px;
    padding-left: 12px;
    margin: 6px 0px;
   }
   .bottom-btn{
    text-align: center;
    margin-top: 20px;
    .btn{
        display: flex;
        justify-content: center;
        width: 100%;
        border-radius: 18px;
        background: #ff6400;
        color: white;
        cursor: pointer;
        border: 0px;
        padding: 8px 4px;
     }
   }
   

</style>