<template>
    <div class="MerTitle">
        <el-container>
            <div class="titleLeft">
                <div class="LeTop">
                    <span class="post">{{ info.jobTitle }}</span>
                    <span class="money">{{ info.salaryRangeId }}</span>
                </div>
                <div class="LeBom">
                    <span>{{ info.jobAddress }}</span>
                    <span>{{ info.jobTime }}</span>
                    <span>{{ info.type != 'full' ? '兼职' : '全职' }}</span>
                </div>
            </div>

            <div class="titleRight">
                <div class="RiTop">
                    <el-button type="warning" round @click="getChat">聊一聊</el-button>
                </div>
                <div class="RiBom">
                    <span @click = 'getBack'>
                        <i class="el-icon-star-off " :class="{back:showback}"></i>
                        <span :class="{back:showback}">{{shou}}</span>
                    </span>
                </div>
            </div>
        </el-container>
    </div>
</template>

<script>
import { getToken } from '@/utils/auth'
import { sendMessageInfo,getUserLeftList } from '@/api/message'
export default {
    data(){
        return{
            showback:false,
            shou:"收藏",
            info:{},
            message:{}
        }
    },
    created(){
        this.getInfo()
    },
    methods:{
        getInfo(){
            if(localStorage.getItem("prtInfo") == null || localStorage.getItem("prtInfo") == '') {
                this.$router.push("/")
            }
            this.info = JSON.parse(localStorage.getItem("prtInfo"))
        },
        getBack(){
            this.showback = !this.showback
            this.showback? this.shou='已收藏':this.shou = '收藏'
        },
        getChat(){
            //先给这个人发送一条消息
            if(getToken() == null || getToken() == '') {
                this.$message({
                    type:"error",
                    message:"暂没有登陆，请登录！"
                })
                return;
            }


            this.message.message = "你好，看了贵司的招聘信息，跟我的履历非常匹配，可以聊聊吗"
            this.message.toUserName = this.info.userId
            console.log(this.info)
            sendMessageInfo(this.message).then(res => {})
            //这里使用全局方法来进行校验是否登录
            this.$CheckLoginStatic('/chat')
        }
    }
}
</script>

<style scoped lang = 'less'>
    .MerTitle{
        width: 100%;
        background: white;
    }
    .el-container{
        display: flex;
        justify-content: space-between;
        padding: 0px 40px;
        display: flex;
     align-items: center;
        .titleLeft{
            padding: 20px 10px;
            .LeTop{
                span{
                    font-size: 1.2rem;
                    font-weight: 700;
                }
                .money{
                    color: orange;
                    font-size: 1.4rem;
                    font-weight: 700;
                    margin-left: 12px;
                }
            }
            .LeBom{
                margin-top: 12px;
                text-align: left;
                span{
                    margin: 0px 18px;
                    font-size: 12px;
                }
                span:first-child{
                    margin-left: 0px;
                }
            }
        }

        .titleRight{
            .RiTop{
                .el-button{
                    width: 133px;
                    height: 40px;
                    background: #ff6400;
                }
            }
            .RiBom{
                margin-top: 10px;
                span{
                    cursor: pointer;
                }
                .back{
                    color: #ff6400 ;
                }
            }
        }
    }
</style>