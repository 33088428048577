import request from "@/utils/request"

// 教育经历获取
export function getUserStudy(){
    return request({
        url:'/api/school/getSchool',
        method:'GET',
        headers:{
            isToken:true
        }
    })
}
// 教育经历编辑
export function editUserStudy(userInfo){
    return request({
        url:'/api/school/updateSchool',
        method:'POST',
        data:userInfo,
        headers:{
            isToken:true
        }
    })
}

// 教育经历添加
export function appendUserStudy(addd){
    return request({
        url:'/api/school/sendSchool',
        method:"POST",
        data:addd,
        headers:{
            isToken:true
        }
    })
}

// 教育经历删除
export function updateUserStudy(userAdvant){
    return request({
        url:`/api/school/delSchool?schoolId=${userAdvant}`,
        method:'DELETE',
        headers:{
            isToken:true
        }

    })
}