
import request from "@/utils/request"

// 期望获取
export function getUserexpectApi(){
    return request({
        url:'/api/expectations/getExpect',
        method:'POST',
        headers:{
            isToken:true
        }
    })
}
// 期望添加
export function updataUserexpectApi(expectList){
    return request({
        url:'/api/expectations/sendExpect',
        method:'POST',
        data:expectList,
        headers:{
            isToken:true  
        }
    })
}
// 期望编辑
export function editUserexpectApi(edit){
    return request({
        url:'/api/expectations/updateExpect',
        method:'POST',
        data:edit,
        headers:{
            isToken:true  
        }
    })
}

// 期望删除
export function popUserexpectApi(expectId){
    return request({
        url:`/api/expectations/delExpect?expectId=${expectId}`,
        method:'DELETE',
        headers:{
            isToken:true  
        }
    })
}