import request from '@/utils/request'

export function loginForm(sysLoginFormDto){
    return request({
        url:'/api/login/loginForm',
        method:'POST',
        data:sysLoginFormDto,
        headers:{
            isToken:false
        }
    })
}

export function getNickName(){
    return request({
        url:'/api/jobhunting/getNickName',
        method:'POST',
        headers:{
            isToken:true
        }
    })
}

export function logout(){
    return request({
        url:'/logout',
        method:'POST',
        headers:{
            isToken:true
        }
    })
}