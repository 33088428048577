<template>
    <div class="CompanyCenter">
        <div class="content">
            <div class="left">
                <p>公司环境优美，干净整洁，员工之间关系和睦，深耕[行业或领域]，以卓越的产品/服务质量和创新精神赢得市场认可。我们团队专业、高效，致力于为客户创造价值，共创辉煌</p>
            </div>
            <div class="right">
             <el-image
            style="width: 300px;"
            :src="url"
            fit="scale-down">
            </el-image>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            url:'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg'
        }
    }
}
</script>

<style scoped lang = 'less'>
.CompanyCenter{
       width: 1200px;
        margin: 0 auto;
}
    .content{
        padding: 30px;
        background: white;
        display: flex;
        justify-content: space-around;
        align-items: center;
        >div{
            flex: none;
        }
        .left{
            width: 60%;
            text-align: left;
            text-indent: 2rem;
            line-height: 2.0rem;
        }
    }
</style>