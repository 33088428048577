<template>
    <div class="CompanyTop">
        <!-- 导航栏信息 -->
            <BodyNavTop></BodyNavTop>
    </div>
</template>

<script>
import BodyNavTop from '@/components/BodyNavTop.vue'
export default {
    components:{
        BodyNavTop
    }
}
</script>

<style scoped lang = 'less'>
   
</style>