<template>
    <div class="ComHeader">
        <div class="background">
            <div class="content">
                <div class="infrom">
                    <el-avatar shape="square" :size="50" :src="info.avatar == null || info.avatar == '' ? squareUrl : this.$BASE_URL+'/dev-api'+info.avatar"></el-avatar>
                </div>
               <div class="right">
                  <div class="infrom-in">
                    <span class="infrom-name">{{ info.deptName }}</span>
                    <button class="zhu" @click="changeGuan">{{guan}}</button>
                </div>
                <div class="infrom-bom">
                    <span class="Position">地址：</span>
                    <span class="people">{{ info.address }}</span>
                </div>
                <div class="user-infrom" @click = 'getUser()'>
                    <i class="el-icon-edit-outline"></i>
                    编辑个人信息
                </div>
               </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    data(){
        return{
             squareUrl: "https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png",
             gu:false,
             guan:'关注',
             info:{}
        }
    },
    created(){
        this.getBusiness()
    },
    methods:{
        changeGuan(){
            this.gu = !this.gu
            this.gu?this.guan = '已关注':this.guan='关注'
        },
        getUser(){
            this.$CheckLoginStatic('/user')
        },
        getBusiness(){
            let info = localStorage.getItem('businessInfo');
            if(info == '' || info == null) {
                this.$message({
                    type:'error',
                    message:'错误！！！'
                })
                this.$router.push('/')
                return;
            }
            this.info = JSON.parse(info)
           
        }
    }
}   
</script>

<style scoped lang = 'less'>
.content{
    >div{
        flex: none;
    }
}
    .background{
        background: url('../../assets/back1.png') no-repeat;
        height: 180px;
        display: flex;
        align-items: center;
        position: relative;
        .content{
            width: 1200px;
            display: flex;
            justify-content: left;
            margin: 0 auto;
            .infrom{
                margin-right: 18px;
            }
            .right {
                text-align: left;
                color: white;
                .infrom-in{
                    .infrom-name{
                        font-weight: 700;
                        font-size: 18px;
                        margin-right: 18px;
                    }
                    button{
                            color: white;
                            border: 0;
                            line-height: 1.4rem;
                            width: 4rem;
                            border-radius: 10px;
                            background: #ff6400;
                            cursor: pointer;
                    }
                }
                .infrom-bom{
                    font-size: 13px;
                    margin-top: 8px;
                    .Position{
                        margin-right: 10px;
                    }
                }
                
                .user-infrom{
                    position: absolute;
                    right: 117px;
                     bottom: 46px;
                    width: 9rem;
                    color: white;
                    border-radius: 10px;
                    background: #ff6400;
                    line-height: 2.5rem;
                    text-align: center;
                    cursor: pointer;
                     
                }
            }
        }
    }
</style>