import request from "@/utils/request"
// 工作经历获取
export function getUserWork(){
    return request({
        url:'/api/practice/getPractice',
        method:'GET',
        headers:{
            isToken:true
        }
    })
}
// 工作经历添加
export function appendUserWork(append){
    return request({
        url:'/api/practice/sendPractice',
        method:'POST',
        data:append,
        headers:{
            isToken:true
        }
    })
}
// 工作经历修改
export function edittUserWork(edit){
    return request({
        url:'/api/practice/updatePractice',
        method:'POST',
        data:edit,
        headers:{
            isToken:true
        }
    })
}
// 工作经历删除
export function popUserWork(dele){
    return request({
        url:`/api/practice/delPractice?practiceId=${dele}`,
        method:'DELETE',
        headers:{
            isToken:true
        }
    })
}