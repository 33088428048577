<template>
  <div class="business">
          <div class="business-content">
            <div class="left">
              <img src="../../assets/boss.png" style="width:100%;height:100%" alt="">
            </div>
            <div class="from">
              <el-form
                :model="ruleForm"
                status-icon
                :rules="rules"
                ref="ruleForm"
                label-width="100px"
                class="demo-ruleForm"
              >

            <el-form-item label="公司名称" prop="deptName">
              <el-input type="text" v-model="ruleForm.deptName" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="邮箱" prop="email">
              <el-input type="text" v-model="ruleForm.email" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="账号" prop="userName">
              <el-input type="text" v-model="ruleForm.userName" autocomplete="off"></el-input>
            </el-form-item>  
            <el-form-item label="用户名" prop="nikeName">
              <el-input v-model.number="ruleForm.nikeName"></el-input>
            </el-form-item>
            <el-form-item label="手机号" prop="phonenumber">
              <el-input v-model.number="ruleForm.phonenumber"></el-input>
            </el-form-item>
            <el-form-item label="密码" prop="password">
              <el-input type="password" v-model="ruleForm.password" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="确认密码" prop="checkPass">
              <el-input type="password" v-model="ruleForm.checkPass" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="地址" prop="address">
              <el-input  v-model="address"   @focus="show_map" ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button class="sum-btn" type="primary" @click="change_show('btn')">点击注册</el-button>
            </el-form-item>
              </el-form>
        </div>
      </div>

      <!-- pay_show -->
      <div class="alert" v-show="pay_show">
            <div class="al-content">
                <div class="al-top">
                  <span @click="change_show"><i class="el-icon-close"></i></span>
                </div>
                <div class="al-center">
                  <div class="pay">
                    <p>商家注册需支付</p>
                      <div>二维码</div>
                  </div>
                  <div class="subm">
                    <div class="confirm" @click="submitForm">我已支付</div>
                    <div class="cancel" @click = 'change_show'>取消支付</div>
                  </div>
                </div>
            </div>
        </div>
  </div>


</template>


<script>


export default {
  name: 'BaiduMap',  
    data() {

        // 公司名称
      var busin = (rule, value, callback) => {  
        if (!value) {  
            return callback(new Error('公司名称不能为空'));  
        }  
      
        setTimeout(() => {  
            // 正则表达式，检查公司名称是否只包含汉字和英文字母  
            const companyNameRegex = /^[a-zA-Z\u4e00-\u9fa5]+$/;  
      
            if (!companyNameRegex.test(value)) {  
                callback(new Error('请输入有效的公司名称，公司名称应由汉字和英文字母组成'));  
            } else if (value.length < 5 || value.length > 15) { // 假设公司名称长度在5到100个字符之间  
                callback(new Error('公司名称长度应在5到15个字符之间'));  
            } else {  
                callback();  
            }  
          }, 1000);  
      };


      // 账号  
      var merchit = (rule, value, callback) => {  
          if (!value) {  
              return callback(new Error('账号不能为空'));  
          }  
          setTimeout(() => {  
              // 正则表达式，检查账号是否只包含字母、数字、下划线，且长度为6-20个字符  
              const accountRegex = /^[a-zA-Z0-9_]{6,20}$/;  
              
              if (!accountRegex.test(value)) {  
              callback(new Error('请输入正常的账号格式，账号应由6-20个字母、数字或下划线组成'));  
              } else {  
              callback();  
              }  
          }, 1000);  
        };



      var account = (rule, value, callback) => {  
          if (!value) {  
              return callback(new Error('邮箱不可为空'));  
          }  
          const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;  
          
          setTimeout(() => {  
                  if (!emailRegex.test(value)) {  
                  callback(new Error('请输入有效的邮箱地址'));  
                  } else {  
                  callback();  
                  }  
        }, 1000);  
      };

      //   手机
      var perPhoneNumber = (rule, value, callback) => {  
      
        if (!value) {  
            return callback(new Error('手机号不能为空'));  
        }  
      
        setTimeout(() => {  
            // 假设手机号为中国大陆的手机号码，格式为11位数字，且以13、14、15、16、17、18、19开头  
            const phoneNumberRegex = /^1[3456789]\d{9}$/;  
      
            if (!phoneNumberRegex.test(value)) {  
                callback(new Error('请输入有效的手机号码，手机号码应为11位数字，且以13、14、15、16、17、18、19开头'));  
            } else {  
                callback();  
            }  
        }, 1000);  
       };


      // 用户名称
        var perUserName = (rule, value, callback) => {  
          if (!value) {  
              return callback(new Error('用户名称不能为空'));  
          }  
        
          setTimeout(() => {  
              // 假设用户名只能包含字母、数字和下划线，且长度为4到20个字符  
              const userNameRegex = /^[a-zA-Z0-9_]{2,8}$/;  
        
              if (!userNameRegex.test(value)) {  
                  callback(new Error('请输入有效的用户名称，用户名称应包含2到0个字母、数字或下划线'));  
              } else {  
                  callback();  
              }  
          }, 1000);  
        };

        //   密码
        var validatePass = (rule, value, callback) => {
          if (value === '') {
            callback(new Error('请输入密码'));
          } else {
            if (this.ruleForm.checkPass !== '') {
              this.$refs.ruleForm.validateField('checkPass');
            }
            callback();
          }
        };
        //  确认密码
        var validatePass2 = (rule, value, callback) => {
          if (value === '') {
            callback(new Error('请再次输入密码'));
          } else if (value !== this.ruleForm.password) {
            callback(new Error('两次输入密码不一致!'));
          } else {
            callback();
          }
        };
        // https://2d7fa741.r17.cpolar.top/
        return {
          


          //  默认支付面板隐藏显示
          pay_show:false,
          address:'',
          ruleForm: {
            deptName:'' ,//  公司名称
              // address: '', //地址
            email:'', // email
            nikeName:"", // 用户名称
            userName:'', // 账号
            phonenumber:'', // 手机
            password: '', // 密码
            checkPass: '',//确认密码
          },

          rules: {
            password: [
              { validator: validatePass, trigger: 'blur' }
            ],
            checkPass: [
              { validator: validatePass2, trigger: 'blur' }
            ],
            // address: [
            //   { validator: checkAge, trigger: 'blur' }
            // ],
          email:[
              {validator:account,trigger:'blur'}
            ],
            userName:[
              {validator:merchit,trigger:"blur"}
            ],
            deptName:[
              {validator:busin,trigger:'blur'}
            ],
            nikeName:[
              {validator:perUserName,trigger:'blur'}
            ],
            phonenumber:[
              {validator:perPhoneNumber,trigger:'blur'}
            ]
          }
        };
    },
   mounted() {  
   
  },  
    methods: {
      

      change_show(e){
         if (e == 'btn'){
          //  如果验证正确那么就弹出
             this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            this.pay_show = true
          } else {
            console.log('error submit!!');
            return false;
          }
        });
         }else{
           this.pay_show = !this.pay_show
          console.log(this.pay_show);
         }
      },

      submitForm() {
        alert('支付成功')
        // axios.post('', {  
        //   latitude: point.lat, // point是从地理编码器获得的坐标对象  
        //   longitude: point.lng  
        //   })  
        //   .then(response => {  
        //     console.log(response);
        //   })  
        //   .catch(error => {  
        //     console.log(error);
        // });
      },
    
    },  
     beforeDestroy() {  
   
  },  

};
</script>

<style scoped lang ='less'>

.map{
  position: absolute;
    right: -265px;
    bottom: -37px;
}

    .business-content{
      position: relative;
        width: 1184px;
        background: #fff;
        border-radius: 16px;
        margin: 200px auto;
        display: flex;
        justify-content: left;
        .left{
            width: 472px;
            img{
                width: 100%;
                display: block;
            }
        }
        .from{
            width: 60%;
            padding: 0px 40px;
            box-sizing: border-box;
            >form{
                >div{
                margin: 36px 0px;
            }
            }
        }
        .sum-btn{
             width: 141px;;
        }
    }
.alert{
      width: 100vw;
      height: 70vw;
      position: absolute;
      top: 0px;
      left: 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #efefef82;
      .al-content{
            width: 370px;
          background: white;
          border-radius: 10px;
          padding: 22px;
          box-sizing: border-box;
          .al-top{
            text-align: right;
            font-size: 26px;
            i{
              cursor: pointer;
            }
          }
      }
    .pay{
      div{
           width: 200px;
          height: 200px;
          background: cyan;
          margin: 0 auto;
          text-align: center;
          line-height: 200px;
      }
      p{
        margin: 10px 0px;
      }
    }
    .subm{
      display: flex;
      justify-content: center;
      margin-top: 20px;
      >div{
           width: 119px;
          height: 40px;
          background: cyan;
          border-radius: 8px;
          margin: 0px 8px;
          line-height: 40px;
          cursor: pointer;
      }
      .cancel{
          background: #c8c8c8;;
          /* border: 1px solid cyan; */
          color: white;
      }
      .confirm{
        color: white;
      }
    }
}
</style>