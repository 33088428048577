<template>
    <div class="De">
        <el-container>
            <el-header   class="el-headee"  style="
                                    position: fixed;
                                    left: 0px;
                                    top: 0px;
                                    width: 100%;
                                    z-index: 10">
                <!-- 导航栏 -->
                <BodyNavTop  class="el-headerr"></BodyNavTop>
            </el-header>
            <el-main style="margin-top:80px;">
                <!-- 主体 -->
                <DeCon></DeCon>
            </el-main>
        </el-container>
    </div>
</template>

<script>
import DeCon from '@/components/delivery/DeCon.vue'
import BodyNavTop from '../../components/BodyNavTop.vue'
import '../../../publicCss/publicCss.css'
export default {
    components:{
        BodyNavTop,
        DeCon
    }
}
</script>

<style lang='less' scoped>
.el-header{
    padding: 0;
    height: auto !important;
}
</style>