//将要进入的一些页面进行验证，如果登录才可以进，没有登陆则不能进
import { getToken } from "./auth";
export function checkLogin(path){
    if(getToken() == null || getToken() == '') {
        this.$message({
            type:"error",
            message:"暂没有登陆，请登录！"
        })
        return;
    }
    this.$router.push(path)
}