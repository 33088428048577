<template>
  <div class="BodyBanner">
    <!-- <el-carousel :interval="4000" type="card" height="456px">
      <el-carousel-item v-for="(item,index) in list" :key="index">
        <el-image
          style="height: 100%;width: 100%;"
          :src="item.imageUrl"
          ></el-image>
      </el-carousel-item>
    </el-carousel> -->
     <el-carousel indicator-position="outside" height="514px" style=" padding: 0px 87px;">
      <el-carousel-item v-for="(item,index) in list" :key="index">
        <el-image
            style="height: 100% ;width: 100%;"
            :src="item.imageUrl"
            ></el-image>
      </el-carousel-item>
  </el-carousel>  
  </div>
</template>

<script>
import { getListCarouselsImages } from "@/api/carousels"
export default {
  data() {
    return {
      list:[]
    };
  },
  methods:{
    async getList(){
      await getListCarouselsImages().then(res => {
        this.list = res.data.data
      })
    }
  },
  created(){
    this.getList()
  }
};
</script>

<style scoped>
    .el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 200px;
    margin: 0;
  }
  
  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }
  
  .el-carousel__item:nth-child(2n+1) {
    background-color: #d3dce6;
  }

</style>