<template>
  <div class="DeCon">
    <div class="De-content">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="我的投递" name="first">

        <!-- 下面这部分是示例可以删掉，上面是从首页复制过来的 -->
        <ul class="infinite-list" v-infinite-scroll="load" style="overflow:auto">

            <li v-for="(item,index) in list" v-bind:key="index" class="infinite-list-item">
              <div class="BodyList" @click="getMerchant(item)">
                <div class="left-content">
                  <div class="Position-top">
               
                    <span class="Position-name">{{ item.jobTitle }}</span>
                    <span class="addrss">[{{ item.jobAddress }}]</span>
                    <span class="money">{{ item.salaryRangeId }}</span>
                  </div>

                  <div class="Position-Require">
                    <span
                      class="Require"
                      v-for="(items,indexs) in item.requirementsList"
                      :key="indexs"
                    >{{ items }}</span>
                  </div>

                  <div class="Position-bottom">
                    <div class="logo">
                      <!-- <el-avatar :size="21" :src="item.avatar == '' ? circleUrl : item.avatar"></el-avatar> -->
                    </div>
                    <span>{{ item.jobTime }}</span>
                    <span>{{ item.type == 'full' ? '全职' : '兼职' }}</span>
                  </div>
                </div>
                <div class="right-content">
                  <div class="userimg">
                    <el-avatar :size="30" :src="item.avatar == '' || item.avatar == null ? circleUrl : item.avatar"></el-avatar>
                    <span class="user-name">{{ item.nickName }}</span>
                    <span class="user-Position">HR</span>
                  </div>
                </div>
              </div>
            </li>
        </ul> 
             <pagination
            v-show="total>0"
            :total="total"
            :page.sync="queryParams.pageNum"
            :limit.sync="queryParams.pageSize"
            @pagination="load"
          />
          
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>

import { getUserInterViewList } from '@/api/deli';
import { apiPrtInfo } from '@/api/prtTime';

export default {
  data() {
    return {
      activeName: "first",

      // 总条数
      total: 0,
      count: 0,
       circleUrl: "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        type: null,
        salaryRangeId: null,
        jobTitle: null,
        jobDescription: null,
        requirements: null,
        jobTime: null,
        jobAddress: null,
        publishTime: null,
        deadlineTime: null,
        salaryNumber: null,
        status: null
      },
      list: [
        
      ],
      toList:[

      ]
    };
  },
  created(){
    this.load()
  },
  methods: {
    getMerchant(item) {
      //进入详情页面
      if(item != null || item != ''){
          if(item.jobId != null || item.jobId != ''){
              localStorage.setItem('prtId',item.jobId)
              apiPrtInfo(item.jobId).then(res => {
                  localStorage.setItem("prtInfo",JSON.stringify(res.data.data))
                  this.$router.push("/merchant")
              })
          }
      }
    },
    load() {
      this.count = 10;
      getUserInterViewList().then(res => {
        console.log(res.data)
        for(let i = 0; i < res.data.rows.length; i++){
          // res.data.rows[i].requirementsList= res.data.rows[i].requirements.split(",")
        }
        this.list = res.data.rows
        this.total = res.data.total
      })
    },

    handleClick(tab, event) {
      console.log(tab, event);
    }
  }
};
</script>

<style lang='less' scoped>
.DeCon {
  width: 1285px;
  margin: 0 auto;
}
.De-content {
  max-width: 1200px;
  margin: 0 auto;
}
/deep/.el-tabs__nav {
  padding: 10px 0px;
}
/deep/ .el-tabs__item {
  color: #ff6400 !important;
  font-weight: 600 !important;
  font-size: 18px !important;
}
/deep/ .el-tabs__active-bar {
  background: #ff6400;
  width: 76px;
}
/deep/.el-tabs__nav-wrap::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background-color: #f6f6f6;
  z-index: 1;
}
/deep/.el-tabs__header{
        background: white;
    padding: 0px 22px;
    box-sizing: border-box;
        padding-bottom: 24px;
}


.infinite-list{
  padding: 0px 10px;
}
.BodyList {
  margin: 20px 0px;
  width: 100%;
//   border-radius: 20px;
  cursor: pointer;
  background: white;
//   box-shadow: 0px 2px 8px 1px #d9d9d9;
  display: flex;
  justify-content: space-between;
  padding: 18px 30px;
  box-sizing: border-box;
  align-items: center;
  transition: transform 0.2s;
  .left-content {
    width: 70%;
    .Position-top {
      text-align: left;
      span {
        font-size: 18px;
        margin: 0px 6px;
        font-weight: 700;
      }
      .money {
        color: #ff6a00;
        margin: 0px 10px;
        font-size: 20px;
      }
    }
    .Position-Require {
      display: flex;
      justify-content: left;
      margin: 18px 0px;
      span {
        display: inline-block;
        font-size: 11px;
           color: #7c7c7c;
        margin: 6px 5px;
        background: #eaeaea;
        border-radius: 10px;
        padding: 0px 7px;
            line-height: 16px;
      }
    }
    .Position-bottom {
      display: flex;
      justify-content: left;
      .logo {
        display: flex;
        justify-content: left;
      }
      span {
         height: 19px !important;
        display: inline-block;
        font-size: 11px;
        color: #838383;
        margin: 0px 6px;
        background: #eaeaea;
        border-radius: 10px;
        padding: 0px 7px;
        line-height: 19px;
      }
      .el-avatar {
        padding: 0;
      }
    }
  }
  .right-content {
    font-size: 14px;
    .userimg {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .user-name {
      margin: 8px 0px;
    }
  }
}
</style>