import { render, staticRenderFns } from "./MrContent.vue?vue&type=template&id=036a6b44&scoped=true"
import script from "./MrContent.vue?vue&type=script&lang=js"
export * from "./MrContent.vue?vue&type=script&lang=js"
import style0 from "./MrContent.vue?vue&type=style&index=0&id=036a6b44&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "036a6b44",
  null
  
)

export default component.exports