<template>
    <div class="De">
        <el-container>
            <el-header   class="el-headee"  style="
                                    position: fixed;
                                    left: 0px;
                                    top: 0px;
                                    width: 100%;
                                    z-index: 10">
                <!-- 导航栏 -->
                <BodyNavTop  class="el-headerr"></BodyNavTop>
            </el-header>
            <el-main style="margin-top:80px;">
                <!-- 主体 -->
                <div class="word_content">
                    <!-- 每一个模板 -->
                    <div class="items" v-for="(item,index) in imgList" :key="index"> 
                        <div class="img"><!-- imgList -->
                            <img :src='imgUrl+item.wordImage' alt="图片加载失败">
                        </div>      
                        <div class="imgnName">
                            <div class="aaa"></div>
                            <div class="aaa"></div>
                           <div class="Name">
                             <span>{{item.wordName}}</span> <!-- {{item.wordName}} -->
                           </div>
                        </div>
                        <div class="black">
                            <div class="btn">
                                <button style="margin-right:10px;" @click="showimg(item.wordImage)">点击预览</button>
                                 <button style="margin-left:10px;" @click="getWord(index)">点击下载</button>
                            </div>
                        </div>
                    </div>
                     <!-- 预览的模板  imgUrl+item.wordImage-->  
                    <div class="yulan" v-if="yulan">
                        <div class="con" style="position:relative;">
                            <img :src='nowsrc' alt="图片加载失败">
                             <span @click="yulan = false">
                                 ×
                            </span>
                        </div>
                    </div>
                </div>
            </el-main>
        </el-container>
    </div>
</template>

<script>
import BodyNavTop from '../../components/BodyNavTop.vue'
import {getUserWord,updataUserWord} from "@/api/UserWord"

import '../../../publicCss/publicCss.css'
export default {
    components:{
        BodyNavTop,
    },
    data(){
        return {
            yulan:false,
            nowsrc:'',
            // 渲染的模板图片路径
            imgList:[
               
            ],
            imgUrl:this.$BASE_URL
        }
    },
    created(){
        this.getUserInfoWord() // 加载word模板信息
    },
    methods:{
        // 点击预览
        showimg(src){
            this.yulan = true
            this.nowsrc = src
        },

        // 获取word
        getUserInfoWord(){
            getUserWord().then(res =>{
                let data = res.data
                this.imgList = data.data
                // console.log(this.imgList,this.imgUrl );
               
            })
        },

        // 下载word
        getWord(index){
            let userId = Number(localStorage.getItem('pc_user_name')) // 获取用户ID
            let wordId = this.imgList[index].wordId // 获取wordID

            window.open(this.$BASE_URL+"/api/word/getWord?userId="+userId+"&wordId="+wordId)
        }
    }

}
</script>

<style lang='less' scoped>
.el-header{
    padding: 0;
    height: auto !important;
}
.word_content{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 1478px;
     margin: 0 auto;
    .items{
        margin-right: 20px;
        margin-bottom: 50px;
        position: relative;
        cursor: pointer;
        .img{
            width: 341px;
            img{
                width: 100%;
            }
        }
        .imgnName{
            position: absolute;
            bottom: -40px;
            left: 50%;
            transform: translate(-50%,0px);
            width: 173px;
            height: 37px;
            background: #e05f5f;
            line-height: 37px;
            font-size: 14px;
            color: white;
            
            .aaa{
                width: 30px;
                height: 68px;
                position: absolute;
                /* background: red; */
                top: -37px;
                left: 2px;
                background: url('../../assets/btnaaa.png') -23px 9px no-repeat;
                background-size: 72px;
            }
            .aaa:nth-child(2){
                 left: 149px;
            }
        }
        .black{
            position: absolute;
            left: 0px;
            top: 0px;
            width: 100%;
            height: 100%;
            background: #00000054;
            display: none;
            .btn{
                   position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, 0px);
                button{
                    width: 67px;
                    height: 67px;
                    border-radius: 50%;
                    border: 0px;
                    background: #ff4600;
                    color: white;
                    font-weight: 600;
                    font-size: 12px;
                }
                
            }
        }
    }
    .items:hover .black{
        display: block;
    }

    .yulan{
        position: absolute;
        left: 0px;
        top: 0px;
        background: #00000054;
        z-index: 10;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        // position: relative;
        img{
            width: 450px;
        }
        span{
            cursor: pointer;
            display: flex;
            align-content: center;
            justify-content: center;
            color: white;
            font-size: 30px;
            display: inline-block;
            width: 41px;
            height: 41px;
            line-height: 41px;
            // padding: 11px;
            border-radius: 50%;
            background: #fafafa7f;  
            position: absolute;
            right: -54px;
            top: 0px;
        }
    }
}
</style>