<template>
    <div class="MrContent">
        <div class="illustrate">
            <div class="title"><h4>职位介绍</h4></div>
            <div class="illustrate-span">
                <span v-for="(item,index) in info.requirements" :key="index">{{ item }}</span>
            </div>
            <div class="Require">
                <div v-html="info.jobDescription"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            info:{}
        }
    },
    methods:{
        getInfo(){
            if(localStorage.getItem("prtInfo") == null || localStorage.getItem("prtInfo") == '') {
                this.$router.push("/")
            }
            this.info = JSON.parse(localStorage.getItem("prtInfo"))
            this.info.requirements = this.info.requirements.split(",")
        },
    },
    created(){
        this.getInfo()
    }
}
</script>

<style scoped lang = 'less'>
    .MrContent{
        width: 100%;
        .illustrate{
            background: white;
            padding: 40px;
            display: flex;
            /* justify-content: left; */
            flex-direction: column;
            text-align: left;
            .title{
                font-size: 20px;
            }
            .illustrate-span{
                    margin: 12px 0px;
                    display: flex;
                    justify-content: left;
                    span{
                            color: #6b6b6b;
                        display: block;
                        font-size: 12px;
                        margin: 0px 12px;
                        background: #efefef;
                        padding: 2px 6px;
                        border-radius: 10px;
                        line-height: 1rem;
                    }
                    span:first-child{
                        margin-left:0px ;
                    }
            }
            .Require{
                    font-size: 0.8rem;
            }
        }
    }
</style>